/**
 * 
 * @param {string} str - base64 строка 
 * @param {string} fileName - название файла 
 * @param {object} options - параметры 
 * @param {string} options.type
 * @returns {Promise<File>} файл
 */
const base64toFile = (str, fileName = 'tmp.jpeg', options = { type: 'image/jpeg' }) => {
    return fetch(str)
        .then(res => res.blob())
        .then(blob => new File([blob], fileName, options))
}

export {
    base64toFile
}