import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { api } from '../../App';

import { authActions } from '@r/auth/authSlice';
import { teacherActions } from '@r/teacher/teacherSlice';
import { Constructor, Reviews } from '@c';
import { useSocket, useActions } from '@h';

import CourseMenu from './CourseMenu/CourseMenu';
import { Test } from './Test';

import styles from './style.module.scss';

import { FiUser, FiX, FiStar } from 'react-icons/fi';

import katex from 'katex';
import 'katex/dist/katex.min.css';
window.katex = katex;

export function TeacherHome() {
    const { logoutStart } = useActions(authActions);
    const { saveSchemeStart } = useActions(teacherActions);

    const { data: authData } = useSelector(({ auth }) => auth);
    const { isLoading, scheme, schemeId, courseName } = useSelector(
        ({ teacher }) => teacher
    );

    const [img, setImg] = useState('');
    const [showReview, setShowReview] = useState(null);

    const { socket, initSocket } = useSocket();

    useEffect(() => {
        if (!socket) initSocket();

        return () => {
            if (socket) socket.disconnect();
        };
    }, [socket, initSocket]);

    useEffect(() => {
        if (authData) {
            api.get(`people/photo/${authData?.uid}`, {
                responseType: 'arraybuffer',
            })
                .then((response) =>
                    Buffer.from(response.data, 'binary').toString('base64')
                )
                .then((base64) => {
                    if (base64.trim() === '') {
                        setImg(null);
                    } else {
                        setImg('data:image/jpeg;base64,' + base64);
                    }
                });
        }
    }, [authData]);

    useEffect(() => {
        setShowReview(null);
    }, [schemeId]);

    return !authData ? (
        <Redirect to="/teacher" />
    ) : (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.header_top}>
                    <div className={styles.name}>
                        {authData.lastname} {authData.firstname}{' '}
                        {authData.patronymic}
                    </div>
                    <div className={styles.courseTitle}>{courseName}</div>
                </div>
                <div className={styles.avatar}>
                    {img ? (
                        <img src={img} alt="o_O" />
                    ) : (
                        <FiUser color="rgb(51, 135, 247)" />
                    )}
                </div>
                <button onClick={() => logoutStart()} className={styles.exit}>
                    Выйти
                </button>
            </div>
            <div className={styles.content}>
                <CourseMenu />
                {scheme && (
                    <Constructor
                        initElements={scheme.scheme ? scheme.scheme : []}
                        onSave={(els) =>
                            els &&
                            saveSchemeStart({ schemeId, data: { scheme: els } })
                        }
                        disabled={isLoading}
                        className={styles.constructor}
                        onReviews={() => setShowReview(schemeId)}
                    />
                )}
                <Test socket={socket} />
                <div
                    className={styles.reviews_container}
                    style={{
                        display: showReview ? 'block' : 'none',
                    }}
                >
                    <div className={styles.header}>
                        <div className={styles.title}>
                            <span>Отзывы</span>
                            {scheme?.rating ? (
                                <div>
                                    (<span>{scheme.rating}</span>
                                    <FiStar />)
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                        <FiX
                            size={30}
                            className={styles.close}
                            onClick={() => setShowReview(null)}
                        />
                    </div>
                    <div className={styles.reviews}>
                        <Reviews themeId={schemeId} />
                    </div>
                </div>
            </div>
        </div>
    );
}
