import React from 'react';

import { MediaStreamProvider } from './contexts/MediaStreamContext';
import { AudioAnalyserProvider } from './contexts/AudioAnslyserContext';
import { InputAudioProvider } from './contexts/InputAudioContext';
import { VisualiserCanvas } from './VisualiserCanvas';

const AudioVisualiser = ({
    canvasOption = {
        width: 600,
        height: 60,
        color: '#006eff',
    },
}) => {
    return (
        <MediaStreamProvider video={false} audio={true}>
            <InputAudioProvider>
                <AudioAnalyserProvider>
                    <VisualiserCanvas options={canvasOption} />
                </AudioAnalyserProvider>
            </InputAudioProvider>
        </MediaStreamProvider>
    );
};

export { AudioVisualiser };
