import React from 'react';
import { useSelector } from 'react-redux';

import { FiCheckCircle, FiMinusCircle } from 'react-icons/fi';

import styles from './style.module.scss';

const Finish = (data = {}) => {
    const { currentTheme } = useSelector(({ course }) => course);

    return (
        <div className={styles.data_container}>
            <p className={styles.message}>
                Спасибо, что посетил электронное занятие по теме &laquo;
                {currentTheme.theme}&raquo;
            </p>
            {data.data.results && (
                <>
                    <p>
                        Твоя оценка: {data.data.grade} ({data.data.points}/
                        {data.data.results.length})
                    </p>
                    <p>Вот твои ответы:</p>
                    {data.data.results.map((el) => (
                        <div className={styles.answer}>
                            {el.correct ? (
                                <FiCheckCircle
                                    className={styles.icon}
                                    size={32}
                                    color={'green'}
                                />
                            ) : (
                                <FiMinusCircle
                                    className={styles.icon}
                                    size={32}
                                    color={'red'}
                                />
                            )}
                            <div
                                className={styles.text}
                                dangerouslySetInnerHTML={{
                                    __html: el.question,
                                }}
                            ></div>
                        </div>
                    ))}
                </>
            )}
        </div>
    );
};

export { Finish };
