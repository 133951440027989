import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";

import { Loader } from "@c";
import { useActions } from "@h";
import { authActions } from "@r/auth/authSlice";

import TeacherForm from "./TeacherForm/TeacherForm";

import styles from "./TeacherAuth.module.css";

export function TeacherAuth() {
  const history = useHistory();

  const { schoolList, data, isLoading } = useSelector(({ auth }) => auth);
  const { schoolListStart, teacherAuthStart } = useActions(authActions);

  useEffect(() => {
    schoolListStart();
  }, [schoolListStart]);

  useEffect(() => {
    if (data) history.push('teacherhome')
  }, [data, history])

  if (isLoading) {
    return (
      <div className={styles.wrapper}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <TeacherForm
        authStart={(data) => teacherAuthStart(data)}
        schoolList={schoolList}
      />
    </div>
  );
}
