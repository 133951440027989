import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { Page } from './Page';

const TestPage = () => {
    const [auth, setAuth] = useState(null);

    useEffect(() => {
        const pass = window.prompt('Password');

        console.log(pass);
        if (pass !== '321') {
            setAuth(false);
            return;
        }
        setAuth(true);
    }, []);

    if (auth !== null) {
        if (auth) return <Page />;
        else return <Redirect to="/" />;
    } else return <></>;
};

export { TestPage };
