import { useContext, useState, useEffect } from 'react';

import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';

import { Loader } from '@c';
import { useActions } from '@h';
import { authActions } from '@r/auth/authSlice';
import { ViewContext } from '../utils';

import styles from './AuthForm.module.scss';
import { FiArrowLeft } from 'react-icons/fi';

const AuthForm = ({}) => {
    const {
        alert,
        setAlert,
        alertTimer,
        setAlertTimer,
        setLoginPassAuth,
        setWebcamAuth,
    } = useContext(ViewContext);

    const { isLoading, schoolList } = useSelector(({ auth }) => auth);
    const { schoolListStart, loginByFormStart, toggleError } = useActions(authActions);

    const [openList, setopenList] = useState(false);
    const [schoolName, setSchoolName] = useState(
        (() => {
            const school = localStorage.getItem('schoolName');
            if (!school) {
                return '';
            } else if (
                !['undefined', undefined, null, 'null'].includes(school)
            ) {
                return school;
            }

            return '';
        })()
        // localStorage.getItem('schoolName')
        //     ? localStorage.getItem('schoolName')
        // : ''
    );
    const [schoolTitle, setSchoolTitle] = useState('');
    const [filteredList, setFilteredList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(0);

    useEffect(() => {
        schoolListStart();
    }, []);

    useEffect(() => {
        if (schoolList.length > 0 && schoolName && !schoolTitle) {
            const school = schoolList.find((el) => el.Name === schoolName);
            setSchoolTitle(school.Title);
        }
    }, [schoolList]);

    const formik = useFormik({
        initialValues: {
            password: '',
            schooltype: 'lms',
            username: '',
            school: '',
        },
        onSubmit: (values) => {
            loginByFormStart({ ...values, school: schoolName });
            setSchoolTitle('');
            localStorage.setItem('schoolName', schoolName);
        },
    });

    const selectSchool = (item) => {
        setopenList(false);
        setSchoolTitle(item.Title);
        setSchoolName(item.Name);
    };

    const inputSchoolName = (e) => {
        setopenList(true);
        setSelectedItem(0);
        setSchoolTitle(e.target.value);
    };

    useEffect(() => {
        if (schoolList.map((i) => i.Title).includes(schoolTitle)) {
            setFilteredList(schoolList);
        } else if (schoolTitle) {
            const filteredList = schoolList.filter((item) =>
                item.Title.toLowerCase().includes(schoolTitle.toLowerCase())
            );
            setFilteredList(filteredList);
        } else {
            setFilteredList(schoolList);
        }
    }, [schoolTitle, schoolList]);

    useEffect(() => {
        const handler = (event) => {
            if (event.keyCode === 40) {
                selectedItem >= filteredList.length - 1
                    ? setSelectedItem(filteredList.length - 1)
                    : setSelectedItem(selectedItem + 1);
            } else if (event.keyCode === 38) {
                selectedItem <= 0
                    ? setSelectedItem(0)
                    : setSelectedItem(selectedItem - 1);
            } else if (event.keyCode === 13) {
                if (openList === true) {
                    event.preventDefault();
                    setopenList(false);
                    setSchoolTitle(filteredList[selectedItem]?.Title);
                    setSchoolName(filteredList[selectedItem]?.Name);
                }
            } else if (event.keyCode === 27) {
                setopenList(false);
            } else return;
        };

        document.addEventListener('keydown', handler);

        return () => {
            document.removeEventListener('keydown', handler);
        };
    }, [selectedItem, filteredList]);

    return (
        <form
            className={styles.login_form}
            autoComplete="on"
            onSubmit={formik.handleSubmit}
        >
            <div
                className={styles.back}
                onClick={() => {
                    setLoginPassAuth(false);
                    setWebcamAuth(Boolean(alert.text));
                    toggleError(false);
                    clearTimeout(alertTimer);
                    setAlertTimer(null);
                    setAlert({});
                }}
            >
                <FiArrowLeft size={30} color="white" />
                <span>Назад</span>
            </div>
            <div
                className={styles.alert}
                onMouseEnter={() => clearTimeout(alertTimer)}
                onMouseLeave={() =>
                    alert.show &&
                    setAlertTimer(
                        setTimeout(
                            () =>
                                setAlert((prev) => ({ ...prev, show: false })),
                            2000
                        )
                    )
                }
            >
                <Alert
                    severity={alert.type}
                    variant="filled"
                    style={{ opacity: alert.show ? 1 : 0 }}
                >
                    {alert.text}
                </Alert>
            </div>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <div className={styles.title}>
                        Выбрать учебное заведение:
                    </div>
                    <div
                        className={styles.select}
                        onMouseEnter={() => setopenList(true)}
                        onMouseLeave={() => setopenList(false)}
                    >
                        <input
                            className={styles.selected}
                            placeholder="Учебное заведение не выбрано"
                            onChange={(e) => inputSchoolName(e)}
                            value={schoolTitle}
                            autoComplete="school"
                        />
                        {openList && (
                            <div className={styles.list}>
                                {filteredList.map((item, i) => (
                                    <div
                                        className={
                                            i === selectedItem
                                                ? styles.listElement +
                                                  ' ' +
                                                  styles.choice
                                                : styles.listElement
                                        }
                                        key={item.Name}
                                        onClick={() => selectSchool(item)}
                                        onMouseEnter={() => setSelectedItem(i)}
                                    >
                                        {item.Title}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className={styles.login_title2}>
                        Введите логин/пароль:
                    </div>
                    <input
                        className={styles.login_input}
                        name="username"
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        placeholder="Логин"
                    />
                    <input
                        className={styles.pass_input}
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        type="password"
                        placeholder="Пароль"
                    />
                    <div className={styles.buttons}>
                        <button className={styles.input_btn}>Войти</button>
                        {/* <button
              type='button'
              className={styles.input_btn}
              onClick={() => {
                setLoginPassAuth(false)
                setWebcamAuth(false)
              }}
            >
              Назад
            </button> */}
                    </div>
                </>
            )}
        </form>
    );
};

export default AuthForm;
