import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import logo from '@img/logo.png';
import mil from '@img/mil.png';

import { api } from '@src/App';

import styles from './style.module.scss';

const Header = () => {
    const authData = useSelector(({ auth }) => auth.data);

    const [img, setImg] = useState('');

    useEffect(
        () =>
            !img &&
            authData &&
            authData.uid &&
            api
                .get(`people/photo/${authData?.uid}`, {
                    responseType: 'arraybuffer',
                })
                .then((response) =>
                    Buffer.from(response.data, 'binary').toString('base64')
                )
                .then((base64) => {
                    setImg('data:image/jpeg;base64,' + base64);
                }),
        [authData, img]
    );

    return (
        <div className={styles.header}>
            <div className={styles.logos}>
                <img src={logo} alt={'o_O'} />
                <img src={mil} alt={'o_O'} />
            </div>
            <div className={styles.school}>
                <p>{authData.school}</p>
            </div>
            <div className={styles.user}>
                <div className={styles.info}>
                    <p>
                        {authData.lastname} {authData.firstname}
                    </p>
                    <p>{authData.class}</p>
                </div>
                <div className={styles.avatar}>
                    <img src={img} alt={'o_O'} />
                </div>
            </div>
        </div>
    );
};

export { Header };
