import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';

import { Loader, Button } from '@c';
import { useActions, useAudio } from '@h';
import { courseActions } from '@r/courses/courseSlice';
import { toClassNames } from '@helps/';

import * as Blocks from './BlockTypes';
import { Input } from './Input';
import { TestContext } from '../utils';

import styles from './style.module.scss';

const Course = ({ onCurseEnd = () => {}, type = '' }) => {
    // const { playAudio } = useAudio();
    const { stopAudio, playAudio } = useContext(TestContext);

    const { isLoading, currentNode, currentTheme } = useSelector(
        ({ course }) => course
    );
    const { currentNodeUpdate, replaySchemeStart, currentNodeStart } =
        useActions(courseActions);

    const [value, setValue] = useState('');

    const toNext = () => {
        stopAudio();
        currentNodeUpdate({
            courseId: currentTheme,
            type: type,
            answer: value,
        });
    };

    const toPrev = () => {
        stopAudio();
        currentNodeUpdate({
            courseId: currentTheme,
            type,
            prev: true,
        });
    };

    const resetBlock = () => {
        stopAudio();
        currentNodeStart({ courseId: currentTheme, type });
    };

    const reset = () => {
        stopAudio();
        replaySchemeStart({ courseId: currentTheme, type });
    };

    useEffect(() => {
        setValue('');
        if (currentNode && currentNode.data?.text) {
            playAudio(
                `${process.env.REACT_APP_API_URL}${currentNode.data.speech}`
            );
        }
    }, [currentNode]);

    return (
        <div className={styles.main}>
            <div className={styles.theme}>{currentTheme.theme}</div>
            <div className={styles.container}>
                {!currentNode || isLoading ? (
                    <Loader color="rgb(0, 110, 255)" />
                ) : (
                    React.createElement(Blocks[currentNode.type], {
                        data: currentNode.data,
                        onAnswerChange: (val) => setValue(val),
                    })
                )}
            </div>
            <div className={styles.buttons}>
                {currentNode?.type === 'question' ? (
                    currentNode.data.type === 'multi' ? (
                        <Button
                            className={styles.button}
                            onClick={() => !isLoading && toNext()}
                            title={'Дальше (ctrl + enter)'}
                            disabled={isLoading}
                        >
                            Подтвердить
                        </Button>
                    ) : (
                        <Input
                            withYesHelps={currentNode.data.type === 'yesNo'}
                            value={value}
                            setValue={setValue}
                            onSend={() => !isLoading && toNext()}
                        />
                    )
                ) : currentNode?.type === 'finish' ? (
                    <Button
                        className={styles.button}
                        onClick={onCurseEnd}
                        disabled={isLoading}
                    >
                        Завершить курс
                    </Button>
                ) : (
                    <Button
                        className={styles.button}
                        onClick={() => !isLoading && toNext()}
                        title={'Дальше (ctrl + enter)'}
                        disabled={isLoading}
                    >
                        Дальше
                    </Button>
                )}
                <div className={styles.update}>
                    <Button
                        className={toClassNames(styles.button)}
                        onClick={reset}
                        disabled={isLoading}
                    >
                        Начать с начала
                    </Button>
                    <Button
                        className={toClassNames(styles.button)}
                        onClick={resetBlock}
                        disabled={isLoading}
                    >
                        Обновить блок
                    </Button>
                    <Button
                        className={toClassNames(styles.button)}
                        onClick={toPrev}
                        disabled={isLoading}
                    >
                        Назад
                    </Button>
                </div>
            </div>
        </div>
    );
};

export { Course };
