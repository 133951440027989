import React, { useState } from 'react';
import { Grid, Button as MUIButton } from '@mui/material';

import styles from './style.module.scss';

const Form = () => {
    const [checked, setChecked] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);

    return <></>;

    // return (
    //     <div className={styles.main}>
    //         <div className={styles.input_block}>
    //             <p>Выберите вопросы, которые нужно оценить</p>
    //             <Grid
    //                 container
    //                 spacing={2}
    //                 justifyContent="center"
    //                 alignItems="center"
    //             >
    //                 {/* <Grid item>{customList(left)}</Grid> */}
    //                 <Grid item>
    //                     <Grid container direction="column" alignItems="center">
    //                         <MUIButton
    //                             sx={{ my: 0.5 }}
    //                             variant="outlined"
    //                             size="small"
    //                             onClick={handleAllRight}
    //                             disabled={left.length === 0}
    //                             aria-label="move all right"
    //                         >
    //                             ≫
    //                         </MUIButton>
    //                         <MUIButton
    //                             sx={{ my: 0.5 }}
    //                             variant="outlined"
    //                             size="small"
    //                             onClick={handleCheckedRight}
    //                             disabled={leftChecked.length === 0}
    //                             aria-label="move selected right"
    //                         >
    //                             &gt;
    //                         </MUIButton>
    //                         <MUIButton
    //                             sx={{ my: 0.5 }}
    //                             variant="outlined"
    //                             size="small"
    //                             onClick={handleCheckedLeft}
    //                             disabled={rightChecked.length === 0}
    //                             aria-label="move selected left"
    //                         >
    //                             &lt;
    //                         </MUIButton>
    //                         <MUIButton
    //                             sx={{ my: 0.5 }}
    //                             variant="outlined"
    //                             size="small"
    //                             onClick={handleAllLeft}
    //                             disabled={right.length === 0}
    //                             aria-label="move all left"
    //                         >
    //                             ≪
    //                         </MUIButton>
    //                     </Grid>
    //                 </Grid>
    //                 {/* <Grid item>{customList(right)}</Grid> */}
    //             </Grid>
    //         </div>
    //     </div>
    // );
};

export { Form };
