import React from 'react'

import { toClassNames } from '@helps/'

import styles from './style.module.scss'

const YesNo = () => {
    return (
        <div className={styles.main}>
            <span className={styles.text}>
                В случае положительного ответа будет выполнен переход по ветки с зеленым выходом
                <div className={toClassNames(styles.point, styles.point__green)} />
            </span>
            <samp className={styles.text}>
                В случае отрицательного ответа будет выполнент переход по ветке с красным выходом
                <div className={toClassNames(styles.point, styles.point__red)} />
            </samp>
        </div>
    )
}

export { YesNo }