import { call, put, takeLatest, select } from 'redux-saga/effects';

import {
    login,
    checkAuth,
    logout,
    schoolListRequest,
    loginByForm,
    changeTypeInformationPercept,
    loginByFace,
    addFace,
    deleteFace,
} from '../../api/auth';
import { base64toFile } from '@helps/';
import { authActions, getFaceScreenshot, getUsername } from './authSlice';

const {
    loginStart,
    loginCheck,
    loginSuccess,
    teacherAuthStart,
    logoutStart,
    resetAuthData,
    reset,
    toggleLoading,
    loginByFormStart,
    loginByFingerStart,
    toggleError,
    schoolList,
    schoolListStart,
    setTypeInformationPercept,
    typeInformationPerceptChange,
    faceAuthStart,
    faceAddStart,
    faceDeleteStart,
    setFaceScreenshot,
    setFaces,
    setIsFaceDeleted,
} = authActions;

export function* authWatcher() {
    yield takeLatest(loginStart.type, authWorker);
    yield takeLatest(loginCheck.type, checkAuthAuthWorker);
    yield takeLatest(logoutStart.type, logoutWorker);
    yield takeLatest(schoolListStart.type, schoolListWorker);
    yield takeLatest(loginByFormStart.type, authByFormWorker);
    yield takeLatest(loginByFingerStart.type, authByFingerWorker);
    yield takeLatest(teacherAuthStart.type, teacherAuthWorker);
    yield takeLatest(
        typeInformationPerceptChange.type,
        setTypeInformationPerceptWorker
    );
    yield takeLatest(faceAuthStart.type, authByFaceWorker);
    yield takeLatest(faceAddStart.type, addFaceWorker);
    yield takeLatest(faceDeleteStart.type, deleteFaceWorker);
}

function* authWorker(action) {
    const img = action.payload;
    let response;
    try {
        yield put(toggleLoading(true));
        response = yield call(login, img);
        yield put(loginSuccess(response.data));
        yield put(toggleLoading(false));
        yield put(toggleError(false));
    } catch (error) {
        yield console.log('Авторизация не прошла, попробуйте снова');
        yield put(toggleError(true));
        yield put(toggleLoading(false));
    }
}

function* authByFormWorker({ payload }) {
    yield put(toggleLoading(true));
    const data = {
        ...payload,
        schooltype: 'lms',
    };

    try {
        const response = yield call(loginByForm, data);
        if (response.data.info) {
            yield put(loginSuccess(response.data.info));
            if (select(getFaceScreenshot)) {
                yield call(addFaceWorker, { payload: { ...data } });
            }
            yield put(toggleLoading(false));
        } else throw new Error('Info in response not found');
    } catch (error) {
        yield console.error('Авторизация не прошла, попробуйте снова', error);
        yield put(toggleError(true));
    } finally {
        yield put(toggleLoading(false));
    }
}

function* authByFingerWorker({ payload }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(loginByForm, payload);
        if (response.data.info) {
            yield put(loginSuccess(response.data.info));
            // if (yield select(getFaceScreenshot)) {
            //     yield call(addFaceWorker, { payload: { ...data } });
            // }
            // yield put(toggleLoading(false));
        } else throw new Error('Info in response not found');
    } catch (error) {
        yield console.error('Авторизация не прошла, попробуйте снова', error);
        yield put(toggleError(true));
    } finally {
        yield put(toggleLoading(false));
    }
}

function* authByFaceWorker({ payload: data }) {
    try {
        yield put(toggleLoading(true));
        yield put(toggleError(null));
        
        const file = yield base64toFile(data, 'tmp.jpeg', {
            type: 'image/jpeg',
        });
        
        const fd = new FormData();
        yield fd.append('image', file);
        
        const response = yield call(loginByFace, fd);

        if (response.status === 200) {
            if (response.data.info !== undefined) {
                yield put(loginSuccess(response.data.info));
            } else {
                yield console.log('login info', response.data);
                yield put(toggleError(response.data));
            }
        } else {
            yield console.log('login info', response.data);
            put(toggleError(response.data));
        }
        yield put(setFaceScreenshot(data));
    } catch (error) {
        yield put(toggleError(true));
        yield console.error('Auth by face error', error);
    } finally {
        yield put(toggleLoading(null));
    }
}

function* addFaceWorker({ payload: { username, password, school, file } }) {
    try {
        yield put(toggleLoading(true));

        let _file;
        if (!file) {
            _file = yield base64toFile(
                yield select(getFaceScreenshot),
                'tmp.jpeg',
                { type: 'image/jpeg' }
            );
        } else {
            yield put(setFaceScreenshot(file));
            _file = yield base64toFile(file, 'tmp.jpeg', {
                type: 'image/jpeg',
            });
        }

        const fd = new FormData();

        if (!_file) throw new Error('File invailed');
        yield fd.append('image', _file);

        let _username;
        if (!username) _username = yield select(getUsername);
        else _username = username;

        if (!_username) throw new Error('Username invailed');

        yield fd.append('username', _username);
        yield fd.append('password', password);
        yield fd.append('school', school);

        const response = yield call(addFace, fd);

        if (response.status === 200) {
            // yield put(setFaceScreenshot(null))
            yield put(setFaces(true));
        } else {
            throw new Error('Add face request error');
        }
    } catch (error) {
        console.error('Add face error:', error);
    } finally {
        yield put(toggleLoading(false));
    }
}

function* deleteFaceWorker() {
    yield put(toggleLoading(true));
    const _base64File = yield select(getFaceScreenshot);

    if (!_base64File) {
        yield put(toggleLoading(false));
        return;
    }

    const _file = yield base64toFile(_base64File, 'tmp.jpeg', {
        type: 'image/jpeg',
    });

    const fd = new FormData();
    yield fd.append('image', _file);

    const response = yield call(deleteFace, fd);

    if (response.status === 200) {
        yield put(setFaces(response.data.faces));
        yield put(setFaceScreenshot(null));
        yield put(setIsFaceDeleted(true));
    } else {
        throw new Error('Add face request error');
    }

    yield put(toggleLoading(false));
}

function* teacherAuthWorker(action) {
    const data = action.payload;
    data.schooltype = 'lms';
    data.type = 'teacher';
    try {
        yield put(toggleLoading(true));
        const response = yield call(loginByForm, data);
        yield put(loginSuccess(response.data.info));
        yield put(toggleLoading(false));
        yield put(toggleError(false));
    } catch (error) {
        yield console.log('Авторизация не прошла, попробуйте снова');
        yield put(toggleError(true));
        yield put(toggleLoading(false));
    }
}

function* checkAuthAuthWorker() {
    try {
        const response = yield call(checkAuth);
        yield put(loginSuccess(response.data));
    } catch (error) {
        yield put(resetAuthData());
    }
}

function* logoutWorker() {
    try {
        yield call(logout);
        yield put(resetAuthData());
        yield put(reset());
    } catch (error) {
        yield yield console.log('Не удалось разлогиниться');
    }
}

function* schoolListWorker() {
    try {
        const response = yield call(schoolListRequest);
        yield put(schoolList(response.data));
    } catch (error) {
        yield console.log('Не удалось получить список школ');
    }
}

function* setTypeInformationPerceptWorker({ payload }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(changeTypeInformationPercept, payload);
        if (response.status === 204) {
            yield put(setTypeInformationPercept(payload));
        }
    } catch (error) {
        yield console.error('Не удалось извменить тип восприятия', error);
    } finally {
        yield put(toggleLoading(false));
    }
}
