import React, { useEffect, useRef } from 'react';

import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './quill.css';

import { toClassNames } from '@helps/classNames';

const formats = [
    'header',
    'size',
    'bold',
    'italic',
    'underline',
    'align',
    'strike',
    'script',
    'blockquote',
    'background',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'color',
    'code-block',
    'formula',
];

var fontSizeStyle = Quill.import('attributors/style/size');
fontSizeStyle.whitelist = ['14px', '24px', '32px', '48px', '60px'];
Quill.register(fontSizeStyle, true);

const CustomToolbar = ({ id }) => (
    <div id={id}>
        <span className="ql-formats">
            <select className="ql-size" defaultValue="24px">
                <option value="14px">14px</option>
                <option value="24px">24px</option>
                <option value="32px">32px</option>
                <option value="48px">48px</option>
                <option value="60px">60px</option>
            </select>
            <select className="ql-header" defaultValue="3">
                <option value="1">Heading</option>
                <option value="2">Subheading</option>
                <option value="3">Normal</option>
            </select>
        </span>
        <span className="ql-formats">
            <button className="ql-bold" />
            <button className="ql-italic" />
            <button className="ql-underline" />
            <button className="ql-strike" />
        </span>
        <span className="ql-formats">
            <button className="ql-list" value="ordered" />
            <button className="ql-list" value="bullet" />
            <button className="ql-indent" value="-1" />
            <button className="ql-indent" value="+1" />
        </span>
        <span className="ql-formats">
            <button className="ql-script" value="super" />
            <button className="ql-script" value="sub" />
            <button className="ql-blockquote" />
        </span>
        <span className="ql-formats">
            <select className="ql-align" />
            <select className="ql-color" />
            <select className="ql-background" />
        </span>
        <span className="ql-formats">
            <button className="ql-link" />
        </span>
        <span className="ql-formats">
            <button className="ql-formula" />
            <button className="ql-code-block" />
            <button className="ql-clean" />
        </span>
    </div>
);

const modules = {
    toolbar: {
        container: 'toolbar',
    },
    history: {
        delay: 500,
        maxStack: 100,
        userOnly: true,
    },
};

const MyQuill = ({
    uniq = '',
    value = '',
    onChange = () => {},
    className = '',
}) => {
    const ref = useRef(null);

    useEffect(() => {
        if (ref.current) {
            const container = ref.current?.editor?.container;
            const observer = new MutationObserver((mutations) => {
                /** @type {HTMLElement} */
                const element =
                    container.getElementsByClassName('ql-tooltip')[0];

                if (parseFloat(element.style.top) < 18.5) {
                    element.style.top = '18.5px';
                }
            });

            const formulaObserver = new MutationObserver((mutations) => {
                if (container.getElementsByClassName('ql-tooltip').length > 0) {
                    observer.observe(
                        container.getElementsByClassName('ql-tooltip')[0],
                        {
                            attributes: true,
                            attributeFilter: ['style'],
                        }
                    );
                    formulaObserver.disconnect();
                }
            });

            formulaObserver.observe(container, {
                childList: true,
                subtree: true,
            });
        }
    }, [ref]);

    return (
        <div className={toClassNames('text-editor', className)}>
            <CustomToolbar id={`${modules.toolbar.container}${uniq}`} />
            <ReactQuill
                ref={ref}
                value={/[<w+>]/gm.test(value) ? value : `<p>${value}</p>`}
                onChange={onChange}
                theme="snow"
                modules={{
                    ...modules,
                    toolbar: {
                        container: `#${modules.toolbar.container}${uniq}`,
                    },
                }}
                formats={formats}
            />
        </div>
    );
};

export { MyQuill as Quill };
