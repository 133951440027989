import React, { useContext, useEffect, useState } from 'react';

import { api } from '../../../../../../App';
import * as QuestionTypes from './QuestionTypesView';
import { ViewContext } from '../../../../utils';

const Question = ({
    question = {
        question: '',
        answers: [],
        type: '',
    },
    showAnswers = true,
    results = [],
    selectAnswer = () => {},
    index = null,
    value = '',
    setValue = () => {},
    onValueChange = () => true,
    resultsWithWords = [],
    isAudioFinish = false,
    resetText = () => {},
    stopAudio = () => {},
}) => {
    const { lessonPayload, setLessonPayload } = useContext(ViewContext);

    const [type, setType] = useState('default');

    useEffect(async () => {
        setValue('');
        resetText();
        let tmp = { stopAudio };
        if (question.payload) {
            tmp = {
                ...tmp,
                question: question.question,
                type: question.type,
                payload: { ...question.payload },
            };
            if (question?.type === 'jumbledWords') {
                tmp.payload.words = question.answers
                    .map((el) => el.replace(/,|\./g, ''))
                    .join(' ')
                    .toLowerCase()
                    .split(' ')
                    .sort(() => Math.random() - 0.5);

                let normalizedWords = tmp.payload.words;

                await api
                    .post('/utils/normalize', {
                        text: tmp.payload.words.join(' '),
                    })
                    .then(
                        (res) =>
                            res.status === 200 &&
                            (normalizedWords = res.data.words)
                    );

                tmp.payload.normalizedWords = normalizedWords;
                tmp.payload.selectedWords = [];
            }
            if (question?.type === 'distribution') {
                tmp.onChange = (val) => {
                    console.log(val);
                    setValue(val.like.join(' '));
                };
            }
        } else {
            tmp = {
                ...tmp,
                type: 'default',
                ...question,
            };
        }

        setLessonPayload(tmp);
        setType(
            !question.type || question.type === '' ? 'default' : question.type
        );
    }, [question]);

    useEffect(() => {
        if (question?.type === 'jumbledWords' && value) {
            if (
                resultsWithWords[0].textTwo.filter(
                    (el) =>
                        lessonPayload.payload.normalizedWords.indexOf(el) > -1
                ).length >= question.payload.minWords
            ) {
                onValueChange(true);
            } else onValueChange(false);

            setLessonPayload((prev) => ({
                ...prev,
                payload: {
                    ...prev.payload,
                    selectedWords: resultsWithWords[0].textTwo,
                },
            }));
        }
    }, [resultsWithWords]);

    useEffect(() => {
        if (question?.type === 'readingSpeed') {
            setLessonPayload((prev) => ({
                ...prev,
                value,
            }));
        }
    }, [value]);

    useEffect(() => {
        if (question?.type === 'readingSpeed' && results[0] > 0) {
            setLessonPayload((prev) => ({
                ...prev,
                result: results[0],
            }));
        }
    }, [results]);

    useEffect(() => {
        if (question?.type === 'readingSpeed') {
            if (lessonPayload.stoped) onValueChange(true);
            else onValueChange(false);
        }
    }, [lessonPayload]);

    return React.createElement(QuestionTypes[type], {
        index,
        question: question.question,
        showAnswers,
        answers: question.answers,
        results,
        selectAnswer,
        value,
        isAudioFinish,
        setValue,
    });
};

export { Question };
