import React from 'react';

const RuTubePlayer = ({ videoId }) => {
    return (
        <iframe
            src={`https://rutube.ru/pl/?pl_id&pl_type&pl_video=${videoId}`}
            frameborder="0"
            allow="clipboard-write"
            webkitAllowFullScreen
            mozallowfullscreen
            allowfullscreen
        />
    );
};

export { RuTubePlayer };
