import { useEffect, useState } from 'react'

/**
 * 
 * @param {null|number} limit 
 * @param {Function} callback 
 * @returns 
 */
const useTimer = (limit = null, callback = () => { }) => {
    const [seconds, setSeconds] = useState(() => limit ? limit : 0)
    const [isActive, setIsActive] = useState(false)

    const reset = () => {
        setSeconds(0)
        setIsActive(false)
    }

    const start = () => {
        setIsActive(true)
    }

    const stop = () => {
        setIsActive(false)
    }

    useEffect(() => {
        let interval = null

        if (isActive)
            interval = setInterval(() => setSeconds(prev => limit ? prev - 1 : prev + 1), 1000)
        else if (!isActive && seconds !== 0)
            clearInterval(interval)

        return () => clearInterval(interval)
    }, [isActive, seconds, limit])

    useEffect(() => {
        if (limit && seconds <= 0) {
            callback()
            stop()
        }
    }, [seconds, limit])

    return { seconds, start, stop, reset }
}

export { useTimer }