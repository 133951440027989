import React, { useEffect, useContext, useState, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useActions, useAudio} from '@h';
import { profileActions } from '@r/profile/profileSlice';
import { testsActions } from '@r/tests/testsSlice';

import { TypeTestView } from '../ElectronicLesson/TypeTestView';
import { AleshaHeadContext, ViewContext, DialogContext } from '../../../utils';

import styles from './style.module.scss';
import { useLoadAudioFile } from '@h/useLoadAudioFile';
import { Button } from '@mui/material';
import { useSTT } from '@h/index';
import { Socket } from 'socket.io-client';
import { api } from '../../../../../App';
import { Loader, Loader2 } from '@c/index';


const Patriotism = () => {
    
    const { changeSelectedCommand } = useActions(profileActions);
    const { getNewPerceptionText } = useActions(testsActions);
    
    // const { recorder, record, setRecord as setRecord_, translatedText } = useSTT(Socket)
    
    const { setRecord, setValue, record, resetText, clean, value, onTestFinish, setSelectedCommand } = useContext(DialogContext);
    const { setAleshaSpeaking } = useContext(AleshaHeadContext);
    const { setView } = useContext(ViewContext);
    const { stopAudio } = useAudio();
    const audio = useMemo(() => new Audio(), []);
    const dataPromiseCancelRef = useRef(null);
    const { getFileUrl } = useLoadAudioFile(dataPromiseCancelRef);

    const [isShowText, setIsShowText] = useState(false)
    const [isRec, setIsRec] = useState(1)
    const [answer, setAnswer] = useState('')
    const [phrase, setPhrase] = useState('')
    const [loading, setLoading] = useState(false)

    const timerRef = useRef(null)

    useEffect(() => {
        changeSelectedCommand('Патриотизм')
        setRecord()
        setValue('');
        _stopAudio()
        setTimeout(() => { setIsRec(2) }, 6000);
        return () => {
            changeSelectedCommand('');
            close()
            clean(['назад', 'в меню'], null)
        }
    }, [])

    useEffect(() => {
        const sayClose = /меню|назад/gm.test(value.toLowerCase())
        // const haveAnswer = value.includes('конец')
        if (sayClose) {
            close()
            return
        }
        clearTimeout(timerRef.current)
        timerRef.current = setTimeout(() => {
            getGPTRequest(value) 
            setRecord(false)
        }, 2000);
    }, [value])

    const playAudioFile = (url) => {
        audio.src = url;
        audio.onended = () => {
            // Отключаем анимацию
            setAleshaSpeaking(false);
            // Включаем микрофон
            setRecord(true);
        };
        audio
        .play()
        .then((_) => {
            setAleshaSpeaking(true);
            setRecord(false);
        })
        .catch((err) => {
            console.error(err);
            setAleshaSpeaking(false);
            setRecord(true);
        });
        setValue('');
        resetText();
    };
    
    const getGPTRequest = async (search) => {
        if (!search || search.length < 7) return;
        setLoading(true)
        const payload = {patriotism: true, search}
        const { data } = await api.post('/commands/gpt', payload)
        const [points, text] = data.content.split('. Комментарий:')
        const rating = points.split(':')[1]
        const phrase = parseInt(rating) > 5
            ? 'Твой ответ верный. Хочу чтобы ты изучил и другие темы.'
            : 'Я думаю, тебе стоит поупражняться на тренажере.'
        const textAudio = `${text} ${phrase}`
        setAnswer(text)
        setPhrase(phrase)
        setIsRec(4)
        setIsShowText(true)
        // window?.open('https://oge.edumil.ru', '_blank')?.focus();
        getAndPlayAudio([textAudio])
        setLoading(false)
    }
    

    const getAndPlayAudio = async (text) => {
        getFileUrl(
            text.join(' '),
            () => {
                stopAudio();
                audio.pause();
                audio.currentTime = 0;
            },
            
            (url) => playAudioFile(url)
        );
    };

    const close = () => {
        onTestFinish()
        setView(undefined)
        _stopAudio()
        setAleshaSpeaking(false);
    }

    const _stopAudio = (withRecordStart = true) => {
        if (
            !dataPromiseCancelRef.current.finished &&
            !dataPromiseCancelRef.current.cancelled
        ) {
            console.log('canceling');
            dataPromiseCancelRef.current.cancel();
        }
        setValue('');
        resetText();
        setAleshaSpeaking(false);
        // withRecordStart && !record && setRecord(true);
    
        withRecordStart && setRecord((prev) => prev || true);
        audio.pause();
    };

    return (
      <div className={styles.wrapper}>
        <div className={styles.main}>
          {
            loading
                ? <Loader2/>
                : isShowText ? (
                    <>
                    <p className={styles.message}>{answer}</p>
                    <p className={styles.message}>
                        {phrase} — {" "}
                        <a
                        className={styles.link}
                        id="link"
                        href="https://oge.edumil.ru"
                        target="_blank"
                        rel='noopener noreferrer'
                        >
                        ИИ-тренажёр для подготовки к ОГЭ
                        </a>
                    </p>
                    </>
                ) : (
                    <>
                    <p className={styles.message}>
                        Расскажи своими словами. Что такое "патриотизм"?
                    </p>
                    <div className={styles.recDiv}>
                        {isRec == 2 ? (
                        <>
                            <div className={styles.wrapperRec}>
                            <div className={styles.requestLoader}>
                                <span></span>
                            </div>
                            <span className={styles.textRec}>Запись...</span>
                            </div>
                        </>
                        ) : (
                        <></>
                        )}
                        {isRec == 1 ? (
                        <>
                            <svg
                            className={styles.mic}
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            version="1.1"
                            width="68"
                            height="68"
                            viewBox="0 0 24 24"
                            >
                            <g>
                                <rect
                                id="top-part"
                                x="9"
                                y="3.5"
                                width="6"
                                height="12"
                                rx="3"
                                fill="#5A8CF6"
                                />
                                <animate
                                className={styles.micro}
                                xlinkHref="#top-part"
                                attributeName="height"
                                from="6"
                                to="12"
                                dur="400ms"
                                begin="indefinite"
                                fill="freeze"
                                />
                                <animate
                                className={styles.micro}
                                id="drill"
                                xlinkHref="#top-part"
                                attributeName="y"
                                from="9"
                                to="3.5"
                                begin="indefinite"
                                dur="400ms"
                                fill="freeze"
                                />
                            </g>
                            <g stroke="#5A8CF6" fill="none">
                                <g>
                                <circle
                                    id="baloon"
                                    cx="12"
                                    cy="12"
                                    r="6"
                                    stroke-width="1.5"
                                    stroke-dasharray="37.7"
                                    stroke-dashoffset="18.8"
                                />
                                <animate
                                    className={styles.micro}
                                    xlinkHref="#baloon"
                                    attributeName="stroke-dashoffset"
                                    from="0"
                                    to="18.8"
                                    dur="400ms"
                                    begin="indefinite"
                                    fill="freeze"
                                />
                                </g>
                                <g>
                                <rect
                                    id="leg"
                                    x="11"
                                    y="18"
                                    width="2"
                                    height="4"
                                    fill="#5A8CF6"
                                    stroke="none"
                                    transform-origin="top"
                                />
                                <animate
                                    className={styles.micro}
                                    xlinkref="#leg"
                                    attributeName="height"
                                    from="0"
                                    to="4"
                                    dur="400ms"
                                    begin="indefinite"
                                    fill="freeze"
                                />
                                </g>
                            </g>
                            </svg>
                        </>
                        ) : (
                        <></>
                        )}
                    </div>
                    </>
          )}
        </div>
      </div>
    );
};

export { Patriotism };