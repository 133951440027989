const getAutologout = () => {
    let autologout = window.localStorage.getItem('autologout');
    switch (autologout) {
        case null:
            window.localStorage.setItem('autologout', false);
            autologout = false;
            break;
        case 'false':
            autologout = false;
            break;
        case 'true':
            autologout = true;
            break;
        default:
            autologout = false;
            break;
    }

    return autologout;
};

export { getAutologout };
