import React from 'react'
import YouTube from 'react-youtube'

import styles from './style.module.css'

const Video = ({
    data = {}
}) => {
    return (
        <div>
            <YouTube className={styles.video} videoId={data.id} />
        </div>
    )
}

export { Video }