import React, { useMemo } from 'react'

import { FiMic, FiSend } from 'react-icons/fi'
import { BsFillKeyboardFill } from 'react-icons/bs'

import styles from './style.module.scss'


const Input = ({
    value = '',
    setValue = () => { },
    record = false,
    setRecord = () => { },
    onSend = () => { },
    withYesHelps = false
}) => {
    const yesHelps = useMemo(
        () => ["Да", "Давай", "Конечно", "С удовольствием", "Хорошо", "Ок", "Отлично"],
        []
    );

    const keyHandler = (e) => {
        if ((e.keyCode == 10 || e.keyCode == 13) && e.ctrlKey) {
            onSend()
        }
    }

    return (
        <div className={styles.root}>
            {withYesHelps &&
                <div className={styles.expected_response}>
                    <p className={styles.header}>Ожидаемые ответы</p>
                    <div className={styles.list}>
                        {yesHelps.map((el, i) => <div key={i} className={styles.list_item} onClick={() => setValue(el)}><p>{el}</p></div>)}
                    </div>
                </div>
            }
            <div className={styles.questionBlock}>
                <div className={styles.question_block_content}>
                    <div className={styles.question_block_content_message}>
                        {/* <p>{}</p> */}
                        <textarea
                            placeholder='Пока вы ничего не произнесли'
                            value={value}
                            onChange={({ target: { value } }) => {
                                setValue(value)
                            }}
                            autoFocus={true}
                            onKeyDown={keyHandler}
                        />
                    </div>
                    {/* <BsFillKeyboardFill
                        className={styles.icon}
                        size={25}
                    />
                    <div className={`${styles.mic_container} ${record && styles.mic_container_active}`}>
                        <FiMic
                            className={`${styles.icon} ${record && styles.rec}`}
                            onClick={() => {
                                if (!record) setValue('')
                                setRecord(!record)
                            }}
                            size={25}
                        />
                    </div> */}
                    <FiSend
                        className={styles.icon}
                        size={30}
                        onClick={onSend}
                        title={'Отправить (ctrl + enter)'}
                    />
                </div>
            </div>
        </div>
    )
}

export { Input }