import React from 'react';

import { RuTubeCorusel, Constructor } from '@c/';

import styles from './style.module.scss';

const Page = () => {
    return (
        <div className={styles.main_wrapper}>
            {/* <RuTubeCorusel videos={videos} className={styles.rutube} /> */}
            <Constructor />
        </div>
    );
};

export { Page };
