import React, { useContext, useState, useEffect } from 'react';
import { Handle } from 'react-flow-renderer';

import { ModalWrapper } from '../components';
import { ModalContext } from '../../../Modal';

import { Form } from './Form';

import { FiTrash2, FiEdit } from 'react-icons/fi';
import styles from './style.module.scss';

const GradeDemonstration = ({ onDragStart = () => {} }) => {
    return (
        <div
            className={styles.container_demonstration}
            onDragStart={onDragStart}
            draggable
        >
            <p>Оценивание</p>
        </div>
    );
};

const Handles = ({ children }) => {
    return (
        <>
            <Handle
                type="target"
                position="left"
                id="in"
                className={styles.point_in}
            />
            {children}
            <Handle
                type="source"
                position="right"
                id="out"
                className={styles.point_out}
            />
        </>
    );
};

const dataInitValue = {
    onChange: () => {},
    onRemove: () => {},
};

const Grade = ({ id, data }) => {
    const { show, close } = useContext(ModalContext);
    const [_data, setData] = useState({
        ...dataInitValue,
        ...data,
    });

    useEffect(() => {
        _data.onChange(id, _data);
    }, [_data, id]);

    const editOpen = () => {
        show(
            <ModalWrapper onButtonClick={() => close()}>
                <Form
                    data={_data}
                    onChange={(val) => setData((prev) => ({ ...prev, ...val }))}
                />
            </ModalWrapper>
        );
    };

    return (
        <div className={styles.container}>
            <Handles>
                <FiTrash2
                    className={styles.icon}
                    onClick={() => data.onRemove(id)}
                    size={30}
                    color={'red'}
                />
                <FiEdit
                    className={styles.edit}
                    size={30}
                    color={'#3b82f6'}
                    onClick={() => editOpen()}
                />
                <p className={styles.header}>Оценивание</p>
            </Handles>
        </div>
    );
};

export { GradeDemonstration, Grade };
