import React from 'react';

import { toClassNames } from '@helps';

import styles from './style.module.scss';

const InfoBlock = ({ className }) => {
    return (
        <div className={toClassNames(styles.main, className)}>
            Поддерживаются следующиее специальные слова:
            <ul>
                <li>
                    <b>firstname</b> - Имя пользователя
                </li>
                <li>
                    <b>patronymic</b> - Отчество пользователя
                </li>
                <li>
                    <b>lastname</b> - Фамиля пользователя
                </li>
                <li>
                    <b>group</b> - группа
                </li>
                <li>
                    <b>school</b> - название школы
                </li>
                <li>
                    <b>time</b> - текущее время на момент прохождения темы
                    учеником
                </li>
                <li>
                    <b>date</b> - текущая дата на момент прохождения темы
                    учеником
                </li>
                <li>
                    <b>course</b> - название предмета
                </li>
                <li>
                    <b>theme</b> - название темы
                </li>
                <li>
                    <b>teacher.firstname</b> - Имя преподавателя
                </li>
                <li>
                    <b>teacher.patronymic</b> - Отчество преподавателя
                </li>
                <li>
                    <b>teacher.lastname</b> - Фамилия преподавателя
                </li>
            </ul>
            Специальные слова используется через фигурные скобки в следующем
            формате:
            <em>{`Привет {firstname}, как дела? Твоя фамилия {lastname}?`}</em>{' '}
            {/* и т.д. */}
            <br />
            <div>
                Для работы с математическими, химическими и т.п. формулами можно
                использовать язык{' '}
                <a href="https://katex.org/docs/supported.html" target="_blank">
                    KaTeX
                </a>
                , примеры использоватния которого находятся по ссылке.
            </div>
        </div>
    );
};

export { InfoBlock };
