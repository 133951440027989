import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    // User info
    data: undefined,
    // Loadings and erros
    isLoading: false,
    error: false,
    // School list
    schoolList: [],
    // Face image (base64)
    faceScreenshot: null,
    isFaceDeleted: false,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        ...initialState,
    },
    reducers: {
        // Teacher auth
        teacherAuthStart: () => {},

        // Auth
        loginStart: () => {},
        loginByFormStart: () => {},
        loginByFingerStart: () => {},
        loginCheck: () => {},
        loginSuccess: (state, { payload }) => {
            state.data = payload;
        },

        // Logout
        logoutStart: () => {},
        resetAuthData: (state) => {
            state.data = null;
        },
        reset: () => initialState,

        // Loadings and errors
        toggleLoading: (state, { payload }) => {
            state.isLoading = payload;
        },
        toggleError: (state, { payload }) => {
            state.error = payload;
        },

        // School list
        schoolListStart: () => {},
        schoolList: (state, action) => {
            state.schoolList = action.payload;
        },

        // Student type information percept
        typeInformationPerceptChange: () => {},
        setTypeInformationPercept: (state, { payload }) => {
            state.data.typeInformationPercept = payload;
        },

        // Faces auth
        setFaceScreenshot: (state, { payload }) => {
            state.faceScreenshot = payload;
        },
        setFaces: (state, { payload }) => {
            state.data.faces = payload;
        },
        setIsFaceDeleted: (state, { payload }) => {
            state.isFaceDeleted = payload;
        },
        faceAuthStart: () => {},
        faceAddStart: () => {},
        faceDeleteStart: () => {},
    },
});

// SELCTORS
export const getFaceScreenshot = (globalState) =>
    globalState.auth.faceScreenshot;
export const getUsername = (globalState) => globalState.auth.data.username;

// ACTIONS
export const authActions = authSlice.actions;

// REDUSERS
export const auth = authSlice.reducer;
