import React, { useContext, useEffect } from 'react';

import { Answers } from '../../Answers';
import { DialogContext } from '../../../../../../utils';

import styles from './style.module.scss';

const JumbledWords = ({
    index = null,
    question = '',
    answers = [],
    results = [],
    selectAnswer,
    showAnswers,
}) => {
    const { setTextCleanup } = useContext(DialogContext);

    useEffect(() => {
        setTextCleanup(false);

        return () => {
            setTextCleanup(true);
        };
    }, []);

    return (
        <div className={styles.main}>
            <p className={styles.header}>
                {index !== null && index}. {question}
            </p>
            {showAnswers && (
                <Answers
                    answers={answers}
                    results={results}
                    selectAnswer={selectAnswer}
                />
            )}
        </div>
    );
};

export { JumbledWords };
