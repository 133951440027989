import React, { useState, useEffect } from 'react';
import * as diff from 'diff';

import styles from './style.module.scss';

window.diff = diff;

const Diff = ({
    text1 = '',
    text2 = '',
    mode = 'words',
    onChange = () => {},
}) => {
    const [groups, setGroups] = useState([]);
    const [nodes, setNodes] = useState(<></>);

    useEffect(() => {
        switch (mode) {
            case 'words':
                setGroups(diff.diffWords(text1, text2));
                break;
            default:
                setGroups(diff.diffChars(text1, text2));
                break;
        }
    }, [mode, text1, text2]);

    useEffect(() => {
        let errors = 0;
        const readedText = [];

        setNodes(
            groups.map(({ value, added, removed }, i) => {
                let style = '';

                if (!added && !removed) {
                    readedText.push(value);
                }
                if (added) {
                    style = styles.added;
                    readedText.push(value);
                }
                if (removed) {
                    if (i === groups.length - 1 && value.split(' ').length > 1)
                        return (
                            <span key={`${i}`} className={styles.not_read}>
                                {value}
                            </span>
                        );
                    else errors++;

                    style = styles.removed;
                }

                return (
                    <span key={`${i}`} className={style}>
                        {value}
                    </span>
                );
            })
        );

        onChange(errors);
    }, [groups]);

    return <div className={styles.result_text}>{nodes}</div>;
};

export { Diff };
