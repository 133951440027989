import React, { useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';

import { useActions } from '@h';
import { courseActions } from '@r/courses/courseSlice';
import { profileActions } from '@r/profile/profileSlice';

import { ViewContext, DialogContext, viewInitState } from '../../../utils';
import { ThemeSelector } from '../components';

import styles from './style.module.scss';

export const Performance = () => {
    const { COMPONENT_NAME, setView, themeSelector, setThemeSelector } =
        useContext(ViewContext);
    const { setCheckCommands, clean } = useContext(DialogContext);

    const { currentCourse, currentTheme, themes, typeInformationPercept } =
        useSelector(
            ({
                course: { currentCourse, currentTheme, themes },
                auth: {
                    data: { typeInformationPercept },
                },
            }) => ({
                currentCourse,
                currentTheme,
                themes,
                typeInformationPercept: ![null, 'null'].includes(
                    typeInformationPercept
                ),
            })
        );
    const { setCurrentCourse, setCurrentTheme, themesStart } =
        useActions(courseActions);
    const { changeSelectedCommand } = useActions(profileActions);

    useEffect(() => {
        setCheckCommands(false);
        changeSelectedCommand('Успеваемость');
        setThemeSelector({
            ...viewInitState.themeSelector,
            cleaningAfterThemeSelect: true,
            course: currentCourse,
            themes: themes,
            theme: currentTheme,
        });

        return () => {
            if (!themeSelector.theme) {
                setThemeSelector({ ...viewInitState.themeSelector });
                if (typeInformationPercept) {
                    clean(['назад', 'в меню'], null);
                }
                changeSelectedCommand('');
            }
        };
    }, []);

    useEffect(() => {
        setThemeSelector((prev) => ({
            ...prev,
            themes: themes,
        }));
    }, [themes]);

    useEffect(() => {
        setThemeSelector((prev) => ({
            ...prev,
            theme: currentTheme,
        }));
    }, [currentTheme]);

    useEffect(() => {
        if (themeSelector.course) {
            if (!themeSelector.theme) {
                setCurrentTheme(null);
            } else {
                if (themeSelector.course && themeSelector.theme) {
                    setThemeSelector((prev) => ({
                        ...prev,
                        checking: false,
                    }));
                }
            }
        }
    }, [themeSelector.theme]);

    useEffect(() => {
        console.log(themeSelector);
    }, [themeSelector]);

    return (
        <div className={styles.wrapper}>
            <ThemeSelector
                selectedCourse={currentCourse}
                onCourseSelect={(course) => {
                    setThemeSelector((prev) => ({ ...prev, course }));
                    setCurrentCourse(course || null);
                    if (course && course.CourseID !== currentCourse?.CourseID) {
                        themesStart({
                            id: course.CourseID,
                            type: 'performance',
                        });
                    }
                }}
                themes={themes}
                onThemeSelect={(theme) => {
                    setThemeSelector((prev) => ({ ...prev, theme }));
                    setCurrentTheme(theme || null);
                    if (theme) {
                        setView(COMPONENT_NAME.ELECTRINIC_LESSON);
                    }
                }}
            />
        </div>
    );
};
