import React from 'react'
import styles from './Loader.module.css'

export const Loader2 = () => {
  return (
    <div className={styles.loader}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}
