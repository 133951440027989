import { createSlice } from '@reduxjs/toolkit';

export const courseSlice = createSlice({
    name: 'course',
    initialState: {
        themes: [],
        currentTheme: null,
        currentCourse: null,

        currentNode: null, // {}

        // Теория
        currentTheoryCourse: null,
        currentTheoryTheme: null,
        theoryThemes: [],
        theoryMaterials: [],

        // Видео уроки
        currentVideoLessonCourse: null,
        currentVideoLessonTheme: null,
        videoLessonThemes: [],

        // Электронные материалы
        currentElectronicMaterialsCourse: null,
        currentElectronicMaterialsTheme: null,
        electronicMaterialsThemes: [],
        electronicMaterials: [],

        isLoading: false,
        inProcess: false,
    },
    reducers: {
        currentNodeStart: () => {},
        currentNodeUpdate: () => {},
        replaySchemeStart: () => {},

        // Получение тем с сервера
        themesStart: () => {},
        theoryThemesStart: () => {},
        theoryMaterialsStart: () => {},
        videoLessonThemesStart: () => {},
        electronicMaterialsThemesStart: () => {},
        electronicMaterialsStart: () => {},

        // Установка текущей темы
        setThemes: (state, { payload }) => {
            state.themes = payload;
        },
        setCurrentTheme: (state, { payload }) => {
            state.currentTheme = payload;
        },

        // Установка текущего курса
        setCurrentCourse: (state, { payload }) => {
            state.currentCourse = payload;
        },
        setCurrentNode: (state, { payload }) => {
            state.currentNode = payload;
        },
        resetCurrentTheme: (state, { payload }) => {
            state.currentTheme = null;
            state.currentNode = null;
        },

        // Установка значений для теории
        setCurrentTheoryCourse: (state, { payload }) => {
            state.currentTheoryCourse = payload;
        },
        setCurrentTheoryTheme: (state, { payload }) => {
            state.currentTheoryTheme = payload;
        },
        setTheoryThemes: (state, { payload }) => {
            state.theoryThemes = payload;
        },
        setTheoryMeterials: (state, { payload }) => {
            state.theoryMaterials = payload;
        },

        // Установка значений для видео уроков
        setCurrentVideoLessonCourse: (state, { payload }) => {
            state.currentVideoLessonCourse = payload;
        },
        setCurrentVideoLessonTheme: (state, { payload }) => {
            state.currentVideoLessonTheme = payload;
        },
        setVideoLessonThemes: (state, { payload }) => {
            state.videoLessonThemes = payload;
        },

        // Установка значений для электронных материалов
        setCurrentElectronicMaterialsCourse: (state, { payload }) => {
            state.currentElectronicMaterialsCourse = payload;
        },
        setCurrentElectronicMaterialsTheme: (state, { payload }) => {
            state.currentElectronicMaterialsTheme = payload;
        },
        setElectronicMaterialsThemes: (state, { payload }) => {
            state.electronicMaterialsThemes = payload;
        },
        setElectronicMaterials: (state, { payload }) => {
            state.electronicMaterials = payload;
        },

        // Лоадеры
        toggleLoading: (state, { payload }) => {
            state.isLoading = payload;
        },

        toggleInProcess: (state, { payload }) => {
            state.inProcess = payload;
        },
    },
});

export const courseActions = courseSlice.actions;

export const course = courseSlice.reducer;
