import { useEffect, useState } from 'react'

let timer = null

const useTextChangeEnd = (func = () => { }, wait = 1000, defaultVaue = '') => {
    const [text, setText] = useState(defaultVaue)

    useEffect(() => {
        if (timer) clearTimeout(timer)
        timer = setTimeout(() => func(), wait)
    }, [text])

    const instantCall = () => {
        if (timer) clearTimeout(timer)
        func()
    }

    return [text, setText, instantCall]
}

export { useTextChangeEnd }