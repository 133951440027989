import React, { useRef, useState, useContext, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Alert from '@mui/material/Alert';

import { Button, Loader, EmotionCamera } from '@c';
import { authActions } from '@r/auth/authSlice';
import { useActions } from '@h';
import { ViewContext } from '../utils';

import { BsCameraVideo } from 'react-icons/bs';
import { FiArrowLeft } from 'react-icons/fi';

import styles from './style.module.scss';

const AuthByFace = () => {
    const {
        setLoginPassAuth,
        setWebcamAuth,
        alert,
        setAlert,
        alertTimer,
        setAlertTimer,
    } = useContext(ViewContext);
    const { enqueueSnackbar } = useSnackbar();

    const { isLoading, faceScreenshot, error, toggleError } = useSelector(
        ({ auth }) => auth
    );
    const webcamRef = useRef(null);

    const [authReqCount, setAuthReqCount] = useState(0);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [buttonDisabledTimer, setButtonDisabledTimer] = useState(null);

    const { faceAuthStart, setFaceScreenshot } = useActions(authActions);

    const auth = () => {
        const face = webcamRef.current.getFace();

        if (face) {
            faceAuthStart(face);
        } else {
            // enqueueSnackbar('Лицо не было найдено на изображении. Авторизуйтесь через логин и пароль', { variant: 'warning', sx: { width: '350px' } })
            setAlert({
                show: true,
                type: 'warning',
                text: 'Лицо не было найдено на изображении. Авторизуйтесь по логину и пароль.',
            });
        }

        setWebcamAuth(false);
        setLoginPassAuth(true);
        setAuthReqCount((prev) => prev + 1);
    };

    const addFace = async () => {
        if (faceScreenshot === null) {
            setAlert({
                show: true,
                type: 'warning',
                text: 'Лицо не было обнаружено на изображении. Лицо не будет добавлено к профилю. Авторизуйтесь по логину и паролю. Или повторите операцию.',
            });
            // enqueueSnackbar('Лицо не было обнаружено на изображении. Лицо не будет добавлено к профилю. Авторизуйтесь по логину и паролю. Или повторите операцию.', { variant: 'warning', sx: { width: '350px' } })
        }

        setWebcamAuth(false);
        setLoginPassAuth(true);
    };

    useEffect(() => {
        if (error) {
            console.log(error);
            // alert('При авторизации произошла ошибка. Попробуйте еще раз или авторизуйтесь по логину и паролю')
            // enqueueSnackbar('При авторизации произошла ошибка. Авторизуйтесь по логину и паролю', { variant: 'error', sx: { width: '350px' } })
            setAlert({
                show: true,
                type: 'error',
                text: 'При авторизации произошла ошибка. Попробуйте еще раз или авторизуйтесь по логину и паролю',
            });
            setWebcamAuth(false);
            setLoginPassAuth(true);
        }
    }, [error]);

    useEffect(() => {
        setButtonDisabledTimer(
            setTimeout(() => setButtonDisabled(false), 5000)
        );
    }, []);

    useEffect(() => {
        if (buttonDisabled) {
            clearTimeout(buttonDisabledTimer);
            setButtonDisabledTimer(null);
        }
    }, [buttonDisabled]);

    return (
        <div className={styles.root}>
            <div
                className={styles.back}
                onClick={() => {
                    setFaceScreenshot(null);
                    setLoginPassAuth(false);
                    setWebcamAuth(false);
                    error && toggleError(false);
                    clearTimeout(alertTimer);
                    setAlertTimer(null);
                    setAlert({});
                }}
            >
                <FiArrowLeft size={30} color="white" />
                <span>Назад</span>
            </div>
            <h1>Посмотрите в камеру и нажмите на кнопку авторизоваться</h1>
            <div className={styles.camera}>
                <EmotionCamera
                    ref={webcamRef}
                    onFaceDetected={() => setButtonDisabled(false)}
                />
                <div className={styles.loader_container}>
                    {isLoading && <Loader />}
                </div>
            </div>
            <div
                className={styles.alert}
                onMouseEnter={() => clearTimeout(alertTimer)}
                onMouseLeave={() =>
                    alert.show &&
                    setAlertTimer(
                        setTimeout(
                            () =>
                                setAlert((prev) => ({ ...prev, show: false })),
                            2000
                        )
                    )
                }
            >
                <Alert
                    iconMapping={{
                        warning: <BsCameraVideo />,
                    }}
                    severity={alert.type}
                    variant="filled"
                    style={{ opacity: alert.show ? 1 : 0 }}
                >
                    {alert.text}
                </Alert>
            </div>
            <div className={styles.buttons}>
                {!isLoading && (
                    <>
                        <Button
                            onClick={() => auth()}
                            className={styles.button}
                            disabled={buttonDisabled}
                        >
                            {buttonDisabled
                                ? 'Поиск лица...'
                                : 'Авторизоваться'}
                        </Button>
                        {authReqCount > 0 && (
                            <Button
                                onClick={() => addFace()}
                                className={styles.button}
                            >
                                Вход по логину
                            </Button>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export { AuthByFace };
