import React from 'react';

import * as Types from './Types';

const AudioPlayer = (props) => {
    return React.createElement(
        Types[props.withHook ? 'withHook' : 'withoutHook'],
        props
    );
};

export { AudioPlayer };
