import React, { useMemo, useContext } from 'react';
import { useSelector } from 'react-redux';

import { Card } from '@c';

import img1 from '@img/cardIcons/1.png';
import img2 from '@img/cardIcons/2.png';
import img3 from '@img/cardIcons/3.png';
import img4 from '@img/cardIcons/4.png';
import img5 from '@img/cardIcons/5.png';
import img6 from '@img/cardIcons/6.png';
import img7 from '@img/cardIcons/7.png';

import { Controllers } from './Controllers';
import { ViewContext } from '../utils';

import * as Views from './Views';

import styles from './style.module.scss';

const COMPONENT = {
    AVATARS: <Views.Avatars />,

    PERFORMANCE: <Views.Performance />,
    PATRIOTISM: <Views.Patriotism />,
    ELECTRINIC_LESSON: <Views.ElectronicLesson />,

    TESTING_VIEW: <Views.Testing />,
    WHOIS_VIEW: <Views.WhoIs />,
    THEORY_VIEW: <Views.Theory />,
    VIDEO_LESSON_VIEW: <Views.VideoLesson />,
    ELECTRONIC_MATERIALS_VIEW: <Views.ElectronicMaterials />,
};

const COMPONENT_NAME = { ...COMPONENT };
Object.keys(COMPONENT).map((key) => (COMPONENT_NAME[key] = key));

const Menu = () => {
    const {
        view,
        setView,
        COMPONENT_NAME: {
            THEORY_VIEW,
            PERFORMANCE,
            PATRIOTISM,
            ELECTRINIC_LESSON,
            TESTING_VIEW,
            VIDEO_LESSON_VIEW,
            ELECTRONIC_MATERIALS_VIEW,
        },
        disableMenuButton,
    } = useContext(ViewContext);

    const cardsState = useMemo(
        () => [
            {
                title: 'Теоретические материалы',
                src: img1,
                onClick: () => setView(THEORY_VIEW),
            },
            {
                title: 'Видео-уроки',
                src: img2,
                onClick: () => setView(VIDEO_LESSON_VIEW),
            },
            {
                title: 'Электронные материалы',
                src: img3,
                onClick: () => setView(ELECTRONIC_MATERIALS_VIEW),
            },
            {
                title: 'Тестирование',
                onClick: () => setView(TESTING_VIEW),
                src: img4,
            },
            {
                title: 'Электронное занятие',
                src: img5,
                onClick: () => setView(ELECTRINIC_LESSON),
            },
            {
                title: 'Успеваемость',
                src: img6,
                onClick: () => setView(PERFORMANCE),
            },
            {
                title: 'Патриотизм',
                src: img7,
                onClick: () => setView(PATRIOTISM),
            },
        ],
        []
    );

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                {!!view && view !== ELECTRINIC_LESSON && (
                    <Controllers
                        setView={setView}
                        disable={disableMenuButton}
                    />
                )}
                {COMPONENT[view] || (
                    <div className={styles.cards}>
                        {cardsState.map((card, i) => (
                            <Card key={i} disable={false} {...card} />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export { Menu, COMPONENT_NAME };
