import { createSlice } from '@reduxjs/toolkit';

const initState = {
    themeSelector: {},
};

// Хранение ссылок на блобы аудио
const audiosSlice = createSlice({
    name: 'audios',
    initialState: {
        ...initState,
    },
    reducers: {
        // Добавление значений
        setThemeSelector: (state, { payload: { key, value } }) => {
            state.themeSelector = { ...state.themeSelector, [key]: value };
        },
        // Отчистка значений
        clearThemeSelector: (state, _) => {
            state.themeSelector = { ...initState.themeSelector };
        },
    },
});

const audios = audiosSlice.reducer;
const audiosActions = audiosSlice.actions;

export { audiosSlice, audios, audiosActions };
