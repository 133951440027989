import { createSlice } from '@reduxjs/toolkit'

export const teacherSlice = createSlice({
    name: 'teacher',
    initialState: {
        periods: [],
        courses: [],
        scheme: null,
        schemeId: null,
        isLoading: false,
        courseName: null
    },
    reducers: {
        periodsStart: () => {},

        coursesStart: () => {},

        schemeStart: () => {},

        saveSchemeStart: () => {},

        saveChangeStart: () => {},

        setPeriods: (state, action) => {
            state.periods = action.payload
        },

        toggleLoading: (state, action) => {
            const boolean = action.payload
            state.isLoading = boolean
        },

        setCourses: (state, action) => {
            state.courses = action.payload
        },

        setCourseName: (state, action) => {
            state.courseName = action.payload
        },

        setScheme: (state, action) => {
            state.scheme = action.payload
        },

        setSchemeId: (state, action) => {
            state.schemeId = action.payload
        }
    },

})

export const teacherActions = teacherSlice.actions

export const teacher = teacherSlice.reducer