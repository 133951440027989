import React from "react";


export const useDate = () => {
  const locale = 'ru';
  const [today, setDate] = React.useState(new Date()); // Save the current date to be able to trigger an update

  React.useEffect(() => {
    const timer = setInterval(() => { // Creates an interval which will update the current data every minute
      // This will trigger a rerender every component that uses the useDate hook.
      setDate(new Date());
    }, 60 * 1000);
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    }
  }, []);

  const days = today.toLocaleDateString(locale, { weekday: 'long' });
  const day = days[0].toUpperCase() + days.slice(1)
  const date = today.toLocaleDateString()
  const time = today.toLocaleTimeString(locale, { hour: 'numeric', hour12: false, minute: 'numeric' });




  return {
    day,
    date,
    time,
  };
};