import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';

import { Button } from '@c';
import { useActions } from '@h';
import { courseActions } from '@r/courses/courseSlice';
import { profileActions } from '@r/profile/profileSlice';
import { DialogContext, ViewContext, viewInitState } from '../../../utils';

import { ThemeSelector, FileSelector } from '../components';

import styles from './style.module.scss';

const ElectronicMaterials = () => {
    const {
        currentElectronicMaterialsCourse,
        currentElectronicMaterialsTheme,
        electronicMaterialsThemes,
        electronicMaterials,
    } = useSelector(({ course }) => course);
    const {
        setCurrentElectronicMaterialsCourse,
        setCurrentElectronicMaterialsTheme,
        electronicMaterialsThemesStart,
        electronicMaterialsStart,
    } = useActions(courseActions);
    const { setRecord, setCheckCommands } = useContext(DialogContext);
    const { themeSelector, setThemeSelector } = useContext(ViewContext);
    const { changeSelectedCommand } = useActions(profileActions);

    const [_electronicMaterials, setElectronicMaterials] = useState([]);

    useEffect(() => {
        setCheckCommands(false);
        changeSelectedCommand('Электронные материалы');
        setThemeSelector({
            ...viewInitState.themeSelector,
            course: currentElectronicMaterialsCourse,
            themes: electronicMaterialsThemes,
            theme: currentElectronicMaterialsTheme,
        });

        return () => {
            setRecord(true);
            setCheckCommands(true);
            changeSelectedCommand('');
            setThemeSelector({ ...viewInitState.themeSelector });
        };
    }, []);

    useEffect(() => {
        if (!!currentElectronicMaterialsTheme) {
            setElectronicMaterials([]);
        }
        if (
            currentElectronicMaterialsCourse?.CourseID === 266 &&
            currentElectronicMaterialsTheme?.id === 217268
        ) {
            setElectronicMaterials([
                {
                    name: 'Формулы приведения.pdf',
                    uploadAt: '23.05.2022 16:11:48',
                    size: '18565118',
                    downloadURI: `${process.env.REACT_APP_API_URL}/electronic_materials/226/217268/Формулы приведения.pdf`,
                    teacher: {
                        firstName: 'Татьяна',
                        patronymic: 'Александровна',
                        lastName: 'Павлова',
                    },
                },
            ]);
            return;
        }
        if (currentElectronicMaterialsTheme) {
            const {
                weekId,
                scheduleId,
                id: themeId,
            } = currentElectronicMaterialsTheme;
            const { id: courseId } = currentElectronicMaterialsCourse;
            electronicMaterialsStart({
                weekId,
                scheduleId,
                courseId,
                themeId,
            });
        }
    }, [currentElectronicMaterialsTheme]);

    useEffect(() => {
        if (
            currentElectronicMaterialsCourse === 266 &&
            currentElectronicMaterialsTheme === 217268
        ) {
            return;
        }
        if (currentElectronicMaterialsTheme) {
            console.log(electronicMaterials);
            setElectronicMaterials(
                electronicMaterials.map((el) => ({
                    ...el,
                    downloadURI: `${process.env.REACT_APP_API_URL}/lms/?action=teacherplanfile&schedule=${currentElectronicMaterialsTheme.scheduleId}&week=${currentElectronicMaterialsTheme.weekId}&file=${el.name}`,
                }))
            );
        }
    }, [electronicMaterials]);

    useEffect(() => {
        setThemeSelector((prev) => ({
            ...prev,
            themes: electronicMaterialsThemes,
        }));
    }, [electronicMaterialsThemes]);

    useEffect(() => {
        if (themeSelector.course && !themeSelector.theme) {
            setCurrentElectronicMaterialsTheme(null);
        } else if (themeSelector.course && themeSelector.theme) {
            setRecord(false);
        }
    }, [themeSelector.theme]);

    return (
        <div className={styles.wrapper}>
            {currentElectronicMaterialsTheme ? (
                <div className={styles.main}>
                    <FileSelector
                        files={_electronicMaterials}
                        themeName={currentElectronicMaterialsTheme.theme}
                    />
                    <Button
                        className={styles.button}
                        onClick={() => {
                            setThemeSelector((prev) => ({
                                ...prev,
                                theme: null,
                            }));
                            setCurrentElectronicMaterialsTheme(null);
                        }}
                    >
                        Другая тема
                    </Button>
                </div>
            ) : (
                <div className={styles.theme_selector_container}>
                    <ThemeSelector
                        selectedCourse={currentElectronicMaterialsCourse}
                        onCourseSelect={(course) => {
                            setThemeSelector((prev) => ({ ...prev, course }));
                            setCurrentElectronicMaterialsCourse(course);
                            if (
                                course &&
                                course.CourseID !==
                                    currentElectronicMaterialsCourse?.CourseID
                            ) {
                                electronicMaterialsThemesStart({
                                    id: course.CourseID,
                                    type: 'materials',
                                });
                            }
                        }}
                        themes={electronicMaterialsThemes}
                        onThemeSelect={(theme) => {
                            setThemeSelector((prev) => ({ ...prev, theme }));
                            setCurrentElectronicMaterialsTheme(theme);
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export { ElectronicMaterials };
