import { api } from '../App'

const getPerceptionType = async () =>
    api.get('/tests/perception')

const getNewPerceptionText = async (textIndex) =>
    api.get(`/tests/perceptionOtherText?textindex=${textIndex}`)

export {
    getPerceptionType,
    getNewPerceptionText
}