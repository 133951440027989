import { api } from '../App';

const getWikiInfo = (search) => {
    const params = new URLSearchParams({
        search: search,
    }).toString();
    return api.get(`commands/wiki/?${params}`);
};

const getWikiInfoById = (id) => {
    return api.get(`commands/wiki/${id}`);
};

const addWikiLike = (id) => {
    return api.put(`commands/wiki/${id}/like`);
};
const addWikiDislike = (id) => {
    return api.put(`commands/wiki/${id}/dislike`);
};

const createWikiRecord = (name, extract) => {
    return api.post(
        `commands/wiki`,
        { name, extract },
        { withCredentials: true }
    );
};

const updateWikiRecord = (id, extract) => {
    return api.put(
        `commands/wiki/${id}`,
        { extract },
        { withCredentials: true }
    );
};

const deleteWikiRecord = (id) => {
    return api.delete(`commands/wiki/${id}`);
};

export {
    getWikiInfo,
    getWikiInfoById,
    addWikiLike,
    addWikiDislike,
    createWikiRecord,
    updateWikiRecord,
    deleteWikiRecord,
};
