import React from "react"
import YouTube from 'react-youtube'

import styles from './style.module.scss'

const Question = ({
    data = {}
}) => {
    return (
        <div className={styles.data_container}>
            <p>{data.text}</p>
            <div className={styles.image_container}>
                {data.images.map((el, i) =>
                    <img
                        key={i}
                        src={el}
                        alt='Изображение'
                    />
                )}
            </div>
            <div className={styles.video_container}>
                {data.videos.map((el, i) =>
                    <YouTube
                        key={i}
                        containerClassName={styles.youtube}
                        videoId={el}
                    />
                )}
            </div>
        </div>
    )
}

export { Question }