import React from 'react';

import { Button } from '@c';

import styles from './style.module.scss';

const ModalWrapper = ({ children, onButtonClick = () => {} }) => {
    return (
        <div className={styles.form_container}>
            {children}
            <div className={styles.buttons_container}>
                <Button onClick={onButtonClick} className={styles.button}>
                    Закрыть и сохранить
                </Button>
            </div>
        </div>
    );
};

export { ModalWrapper };
