import React from 'react';

import { Demonstaration, Nodes } from './NodeTypes';

const Node = (props) => {
    return React.createElement(Nodes[props.type], props);
};

const nodeTypes = {
    finish: Nodes['finish'],
    grade: Nodes['grade'],
    image: Nodes['image'],
    question: Nodes['question'],
    questionWithTypes: Nodes['questionWithTypes'],
    start: Nodes['start'],
    textWithTypes: Nodes['textWithTypes'],
    text: Nodes['text'],
    video: Nodes['video'],
};

export { Node, nodeTypes, Demonstaration };
