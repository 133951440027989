import React, { useEffect, useContext, useState } from 'react';
import { useSelector } from 'react-redux';

import { useActions } from '@h';
import { profileActions } from '@r/profile/profileSlice';
import { testsActions } from '@r/tests/testsSlice';

import { TypeTestView } from '../ElectronicLesson/TypeTestView';
import { ViewContext } from '../../../utils';

import styles from './style.module.scss';

const Testing = () => {
    const { textIndex, currentCourse, currentTheme } = useSelector(
        ({
            tests: {
                perсeptionType: [{ textIndex }],
            },
            course: { currentCourse, currentTheme },
        }) => ({
            textIndex,
            currentCourse,
            currentTheme,
        })
    );
    const { changeSelectedCommand } = useActions(profileActions);
    const { getNewPerceptionText } = useActions(testsActions);

    const { setView, lessonPayload, setDisableMenuButton } =
        useContext(ViewContext);

    const isFromPerformance = () => {
        if (currentTheme && currentCourse) return true;
        return false;
    };

    useEffect(() => {
        if (lessonPayload.finished) {
            if (isFromPerformance()) {
                changeSelectedCommand('Успеваемость');
                setDisableMenuButton(false);
            } else {
                changeSelectedCommand(null);
                setView('');
            }
        }
    }, [lessonPayload]);

    useEffect(() => {
        changeSelectedCommand('Повторное тестирование');
        if (isFromPerformance()) setDisableMenuButton(true);

        return () => {
            getNewPerceptionText(textIndex);
            changeSelectedCommand('');
        };
    }, []);

    return (
        <div className={styles.wrapper}>
            <TypeTestView />
        </div>
    );
};

export { Testing };
