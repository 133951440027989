import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { useActions, useWindowSize, useAudio } from '@h';
import { SoundBuffer } from '../utils';
import { Corusel, Loader, RuTubeCorusel, Button } from '@c';
import { TTS } from '@helps';
import { getCanceledPromise } from '@helps/CanceledPromise';
import { profileActions } from '@r/profile/profileSlice';
import { getWikiInfoById, getWikiInfo, deleteWikiRecord } from '@a/commands';
import {
    ViewContext,
    AleshaHeadContext,
    DialogContext,
} from '../../../../utils';
import { OtherVariant } from '../OtherVariant';

import {
    FiSquare,
    FiRotateCcw,
    FiUser,
    FiEdit,
    FiTrash2,
} from 'react-icons/fi';
import styles from './style.module.scss';

const DispalyAnswer = () => {
    const { width } = useWindowSize();
    const { whoIsPayload, setWhoIsPayload, setView } = useContext(ViewContext);
    const { setRecord, record, resetText, setValue } =
        useContext(DialogContext);
    const { voiceOptions, userId } = useSelector(
        ({ profile, auth: { data } }) => ({
            voiceOptions: profile.voiceOptions,
            userId: data.uid,
        })
    );
    const { changeSelectedCommand } = useActions(profileActions);

    const [loading, setLoading] = useState(true);
    const [playingFinished, setPlayingFinished] = useState(false);

    const { setAleshaSpeaking } = useContext(AleshaHeadContext);
    const { stopAudio } = useAudio(() => {});
    const audio = useMemo(() => new Audio(), []);

    /**@type {AudioContext} */
    const audioCtx = useMemo(
        () =>
            new (window.AudioContext || window.webkitAudioContext)({
                latencyHint: 0.04,
            })
    );
    /**@type {[AudioBufferSourceNode|null, Function]} */
    const [audioSource, setAudioSource] = useState(null);
    const audioSourceRef = useRef(null);
    audioSourceRef.current = audioSource;
    const [lastTextAudio, setLastTextAudio] = useState('');
    const [fullBuffer, setFullBuffer] = useState(null);
    const [audioLoaded, setAudioLoaded] = useState(false);
    const [dataPromiseCancel, setDataPromiseCancel] = useState({
        canceled: false,
        cancel: () => {},
        finished: true,
    });
    const dataPromiseCancelRef = useRef(null);
    dataPromiseCancelRef.current = dataPromiseCancel;

    const fetchData = (body, onEnd, enableMicro = true) => {
        const { promise, cancel, withCanceled } = getCanceledPromise(
            fetch(`${process.env.REACT_APP_API_URL}/yc/speech_synthesize`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            }).then((res) => {
                if (res.status === 500) {
                    getAndPlayAudio(
                        'Вот что мне удалось найти',
                        () => {
                            setWhoIsPayload((prev) => ({
                                ...prev,
                                yesNo: true,
                                showExpectedResponse: true,
                            }));
                            setPlayingFinished(true);
                        },
                        false
                    );
                    cancel();
                    return;
                }
                return res;
            }),
            {
                beforeStart: ({ cancel }) => {
                    setDataPromiseCancel({
                        canceled: false,
                        cancel,
                        finished: false,
                    });
                },
                onCancel: () => {
                    console.log('promise canceled');

                    setDataPromiseCancel({
                        canceled: true,
                        cancel: () => {},
                        finished: true,
                    });
                },
                onReject: () => {
                    setDataPromiseCancel({
                        canceled: false,
                        cancel: () => {},
                        finished: true,
                    });
                },
            }
        );

        promise
            // Если ошибка, выводим в консоль
            .catch((err) => console.error(err))
            // Получаем блоб
            .then(withCanceled((res) => res.blob()))
            // Создаем ссылку на файл из блоба
            .then(withCanceled((res) => URL.createObjectURL(res)))
            .then(
                withCanceled((res) => {
                    // Файл загружен
                    setAudioLoaded(true);
                    // Начинаем вопросизведение
                    playAudioFile(res, onEnd, enableMicro);
                    // Сохраняем буффер для повторного воспроизведения
                    setFullBuffer(res);
                })
            )
            .then(
                withCanceled(() => {
                    setDataPromiseCancel({
                        canceled: false,
                        cancel: () => {},
                        finished: true,
                    });
                })
            );
        console.log(enableMicro);

        return {
            promise,
            cancel,
            withCanceled,
        };
    };

    // Проигрывание файла
    const playAudioFile = (url, onEnd = () => {}, enableMicro = true) => {
        console.log(enableMicro);
        audio.src = url;
        audio.onended = null;
        audio.addEventListener('ended', () => {
            // Отключаем анимацию
            setAleshaSpeaking(false);
            if (enableMicro) {
                // Включаем микрофон
                setRecord(true);
            }
            // Выполняем коллбэк
            onEnd();
        });
        audio.play();
        setAleshaSpeaking(true);
        setRecord(false);
    };

    // Воспроизведение через AudioContext
    const playAudio = (buffer, onEnd = () => {}) => {
        // Останавливаем вопроизведение
        audioSource?.stop(0);
        // Создаем новый буффер
        let source = audioCtx.createBufferSource();
        source.buffer = buffer;
        // Устанавливаем устройство вывода
        source.connect(audioCtx.destination);
        source.onended = () => {
            setAleshaSpeaking(false);
            setRecord(true);
            onEnd();
        };
        source?.start(0);

        setAleshaSpeaking(true);
        setRecord(false);
        // Сохраняем буффер, чтобы можно было остановить
        setAudioSource(source);
    };

    const getAndPlayAudio = async (
        text,
        onEnd = () => {},
        enableMicro = true
    ) => {
        // Оставнливаем все возможные варианты аудио
        whoIsPayload.stopPevAudio();
        audio.pause();
        audio.currentTime = 0;
        setAudioLoaded(false);
        setAleshaSpeaking(false);

        /** Получение озвучки через XMLHttpRequest
         * 
            let req = new XMLHttpRequest();
            req.open(
                'POST',
                `${process.env.REACT_APP_API_URL}/yc/speech_synthesize`
            );
            req.setRequestHeader('Content-Type', 'application/json');
            req.responseType = 'arraybuffer';
            req.onload = () => {
                ctx.decodeAudioData(req.response, (buff) => {
                    source.buffer = buff;
                });
                source.connect(ctx.destination);
                source.start(0);
            };
            req.send(
                JSON.stringify({
                    text,
                    format: 'opus',
                    sampleRateHertz: 48000,
                    ...voiceOptions,
                })
            );
         */

        /** Получение аудио чанками
         * 
            const soundBuffer = new SoundBuffer(audioCtx, 48000);
            let header = null;
            let lastByte = null;
            let firstChunk = true;
                const chunks = [];
    
            fetch(`${process.env.REACT_APP_API_URL}/yc/speech_synthesize`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    text,
                    format: 'lpcm',
                    sampleRateHertz: 48000,
                    ...voiceOptions,
                }),
            })
            .then((res) => res.body)
            .then((rs) => {
                const reader = rs.getReader();
                const decoder = new TextDecoder();
                return new ReadableStream({
                    async start(controller) {
                        while (true) {
                            try {
                                const { done, value } = await reader.read();
                                // console.log(value.byteLength);
    
                                if (done) {
                                    break;
                                }
    
                                let _buffer;
    
                                if (lastByte !== null) {
                                    _buffer = Buffer.concat(
                                        lastByte,
                                        value.buffer
                                    );
                                    lastByte = null;
                                } else {
                                    _buffer = value.buffer;
                                }
    
                                if (_buffer.byteLength % 2 !== 0) {
                                    lastByte = _buffer.slice(
                                        value.byteLength - 1,
                                        value.byteLength
                                    );
                                }
    
                                let frameCount = _buffer.byteLength / 2;
                                let myAudioBuffer = ctx.createBuffer(
                                    1,
                                    frameCount,
                                    16000
                                );
                                for (let channel = 0; channel < 1; channel++) {
                                    let nowBuffering =
                                        myAudioBuffer.getChannelData(
                                            channel,
                                            16,
                                            16000
                                        );
                                    const _bytes = new Uint8Array(_buffer);
    
                                    for (let i = 0; i < frameCount; i++) {
                                        let word =
                                            (_bytes.at(i * 2) & 0xff) +
                                            ((_bytes.at(i * 2 + 1) & 0xff) <<
                                                8);
                                        nowBuffering[i] =
                                            (((word + 32768) % 65536) - 32768) /
                                            32768.0;
                                    }
                                }
    
                                const source =
                                    soundBuffer.addBuffer(myAudioBuffer);
                                // let tmp;
                                // if (header === null) {
                                //     header = value.buffer.slice(0, 8);
                                //     tmp = Float32Array.from(value);
                                // } else {
                                //     tmp = new Uint8Array(
                                //         value.buffer.byteLength +
                                //             header.byteLength
                                //     );
                                //     tmp.set(header, 0);
                                //     tmp.set(value.buffer, header.byteLength);
                                //     tmp = Float32Array.from(tmp);
                                // }
                                // chunks.push(value);
                                // soundBuffer.addChunk(tmp);
    
                                if (firstChunk) {
                                    source.connect(ctx.destination);
                                    // source.start(0);
                                    console.log('play');
                                    firstChunk = false;
                                }
    
                                controller.enqueue(value);
                            } catch (e) {
                                console.error(e);
                                break;
                            }
                        }
                        controller.close();
                        reader.releaseLock();
                    },
                });
            })
            .then((rs) => new Response(rs))
            .catch((err) => console.error(err))
            .then((res) => res.arrayBuffer())
            .then((res) => audioCtx.decodeAudioData(res))
            .then(async (res) => {
                playAudio(res, onEnd);
                setAudioLoaded(true);
                setFullBuffer(res);
            });
         */

        /** Получение через хелпер
         * 
            const res = (await TTS(text)).data;
            const url = URL.createObjectURL(res);
            audio.src = url;
            audio.play();
            audio.onended = () => {
                typeof onEnd === 'function' && onEnd();
                audio.remove();
            setAleshaSpeaking(false);
            setRecord(true);
            };
    
            setAleshaSpeaking(true);
            setRecord(false);
             */

        // Отменяем запрос, если он есть
        if (
            !dataPromiseCancelRef.current.finished &&
            !dataPromiseCancelRef.current.cancelled
        ) {
            console.log('canceling');
            dataPromiseCancelRef.current.cancel();
        }
        console.log(enableMicro);
        // Вызываем новый
        fetchData(
            {
                text,
                format: 'opus',
                sampleRateHertz: 48000,
                ...voiceOptions,
            },
            onEnd,
            enableMicro
        );

        // Сохраняем последний текст, чтобы коррекно повторять воспроизведение
        setLastTextAudio(text);

        // Отключаем загрузку
        setLoading(false);
    };

    const getInfo = async () => {
        // Отключаем микрофон и сбрасываем текст
        setRecord(false);
        resetText();
        setValue('');

        // Получаем информацию по вопросу
        const response = await getWikiInfo(whoIsPayload.text);

        // Устанавливаем ответ в контекст
        whoIsPayload.setInfo(response.data);
        // Проигрываем аудио
        getAndPlayAudio(
            // Если ответ есть, то проиговариваем его, если нет, говорим, что ничего не нашли
            response.data.extract
                ? response.data.extract.replace('/n', '.')
                : 'Я ничего не нашел по твоему вопросу',
            () => {
                if (response.data.extract) {
                    // Устанавливаем значение для перехода логики в компонент диалога
                    setWhoIsPayload((prev) => ({
                        ...prev,
                        yesNo: true,
                        showExpectedResponse: true,
                    }));
                    setPlayingFinished(true);
                } else {
                    // закрываем окно
                    setView('');
                    changeSelectedCommand('');
                }
            },
            !Boolean(response.data.extract)
        );
    };

    const updateInfo = async (id) => {
        // Отключаем микрофон и сбрасываем значение
        setRecord(false);
        resetText();
        setValue('');

        // Получаем новую информацию
        const response = await getWikiInfoById(id);
        if (response.status === 200) {
            // Обновляем значение в контекте
            whoIsPayload.setInfo((prev) => ({
                ...response.data,
                // Удаляем из списка ту информацию, которую будем выводить и
                // вставлем текущую
                otherRecords: [
                    ...prev.otherRecords.filter((el) => el.id !== id),
                    {
                        ...whoIsPayload.info,
                        otherRecords: undefined,
                    },
                ].sort((obj1, obj2) => obj2.sort - obj1.sort),
            }));
            // Проигрываем новый текст
            getAndPlayAudio(response.data.extract.replace('/n', '.'), () => {});
        }
    };

    const editInfo = () => {
        // Останавливаем аудио
        audio.pause();
        // Отключаем микрофон и сбрасываем значение
        setRecord(false);
        resetText();
        setValue('');
        // Переключаем экран
        setWhoIsPayload((prev) => ({ ...prev, type: 'edit' }));
    };

    const deleteInfo = async (id) => {
        // Останавливаем аудио
        audio.pause();
        // Сбрасываем занчение
        resetText();
        setValue('');

        // Запрос
        const response = await deleteWikiRecord(id);
        if (response.status === 200) {
            whoIsPayload.setInfo((prev) => {
                // Удаляем ответ из списка
                const tmp = prev.otherRecords.filter((el) => el.id !== id);
                // Меняем информацию на первую из списка
                return {
                    ...tmp[0],
                    otherRecords: tmp.filter((el) => el.id !== tmp[0].id),
                };
            });
            // Воспроизводим аудио
            getAndPlayAudio(
                'Хорошо, не буду больше никому рассказывать твой ответ',
                () =>
                    getAndPlayAudio(
                        whoIsPayload.info.extract.replace('/n', '.'),
                        () => {}
                    )
            );
        }
    };

    // При изменении текста получаем информацию заново
    useEffect(() => {
        if (whoIsPayload.text) {
            setLoading(true);
            setAudioLoaded(false);
            getInfo();
        }
    }, [whoIsPayload.text]);

    useEffect(() => {
        // Останавливаем аудио
        stopAudio();

        return () => {
            // Если идет запрос, отменяем
            if (
                !dataPromiseCancelRef.current.finished &&
                !dataPromiseCancelRef.current.cancelled
            ) {
                console.log('canceling');
                dataPromiseCancelRef.current.cancel();
            }
            // Останваливаем воспроизведение
            audio.pause();
            audioSourceRef?.current?.stop();
            // Отменем анимацию
            setAleshaSpeaking(false);
            setPlayingFinished(false);
        };
    }, []);

    const onStop = () => {
        // Останавливаем воспроизвелени
        audioSource?.stop();
        audio.pause();
        // Отключаем анимацию
        setAleshaSpeaking(false);
        // Включаем микрофон
        setRecord(true);

        // Переключаемся на логику из компонета диалога
        if (!playingFinished) {
            setWhoIsPayload((prev) => ({
                ...prev,
                yesNo: true,
                showExpectedResponse: true,
            }));
            setPlayingFinished(true);
        }
    };

    useEffect(() => {
        if (record) {
            onStop();
        }
    }, [record]);

    // Задаем вопро при переходе к логике компонента диалога
    useEffect(() => {
        if (playingFinished) {
            getAndPlayAudio('Я правильно ответил на твой вопрос?');
        }
    }, [playingFinished]);

    return (
        <div className={styles.wrapper}>
            {loading ? (
                <Loader color="#006eff" />
            ) : (
                <>
                    <div className={styles.buttons}>
                        {audioLoaded ? (
                            <>
                                <Button
                                    className={styles.stop}
                                    onClick={() => onStop()}
                                >
                                    <FiSquare size={20} />
                                    Стоп
                                </Button>
                                <Button
                                    className={styles.blue_button}
                                    // Если последний проигранный текст - текщий, просто проигрываем,
                                    // если нет, загружаем по новой
                                    onClick={() => {
                                        lastTextAudio ===
                                        whoIsPayload.info.extract
                                            ? playAudioFile(fullBuffer)
                                            : getAndPlayAudio(
                                                  whoIsPayload.info.extract
                                              );
                                        // playAudio(fullBuffer);
                                    }}
                                >
                                    <FiRotateCcw size={20} />
                                    Прочитать заново
                                </Button>
                            </>
                        ) : (
                            <Button disabled={true}>Загрузка...</Button>
                        )}
                        {userId === whoIsPayload.info.user_id && (
                            <>
                                <Button
                                    className={styles.blue_button}
                                    onClick={() => {
                                        editInfo();
                                    }}
                                >
                                    <FiEdit size={20} />
                                    Редактировать
                                </Button>
                                <Button
                                    onClick={() =>
                                        deleteInfo(whoIsPayload.info.id)
                                    }
                                >
                                    <FiTrash2 size={20} />
                                    Удалить
                                </Button>
                            </>
                        )}
                    </div>
                    <div className={styles.main}>
                        {whoIsPayload.info.extract ? (
                            <>
                                <div className={styles.info_container}>
                                    <div className={styles.text}>
                                        <div
                                            className={styles.author_container}
                                        >
                                            <div className={styles.avatar}>
                                                <FiUser size={30} />
                                            </div>
                                            <span className={styles.author}>
                                                {whoIsPayload.info.type ===
                                                'wiki' ? (
                                                    <a
                                                        href={
                                                            whoIsPayload.info
                                                                .link
                                                        }
                                                        title={
                                                            whoIsPayload.info
                                                                .name
                                                        }
                                                        target="_blank"
                                                    >
                                                        Википедия
                                                    </a>
                                                ) : (
                                                    whoIsPayload.info.user_name
                                                )}
                                            </span>
                                        </div>
                                        <div className={styles.line} />
                                        <div className={styles.text_container}>
                                            {whoIsPayload.info?.extract
                                                ?.split('\n')
                                                ?.map((el, i) => (
                                                    <span key={i}>{el}</span>
                                                ))}
                                        </div>
                                        {width < 1645 &&
                                            whoIsPayload.info?.images?.all
                                                ?.length > 0 && (
                                                <Corusel
                                                    containerClassName={
                                                        styles.swiper_container
                                                    }
                                                    swiperClassName={
                                                        styles.swiper
                                                    }
                                                    items={whoIsPayload.info?.images?.all?.map(
                                                        (el) => (
                                                            <div
                                                                className={
                                                                    styles.slide
                                                                }
                                                            >
                                                                <img
                                                                    src={el}
                                                                    alt="Corusel image"
                                                                />
                                                            </div>
                                                        )
                                                    )}
                                                />
                                            )}
                                        {whoIsPayload.info?.videos?.length >
                                            0 && (
                                            <div
                                                className={
                                                    styles.video_container
                                                }
                                            >
                                                <span>
                                                    Видео по теме (rutube.ru):
                                                </span>
                                                {whoIsPayload.info?.videos
                                                    ?.length > 0 && (
                                                    <RuTubeCorusel
                                                        videos={
                                                            whoIsPayload.info
                                                                ?.videos
                                                        }
                                                        className={styles.video}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    {width > 1644 &&
                                        whoIsPayload.info?.images?.all?.length >
                                            0 && (
                                            <Corusel
                                                containerClassName={
                                                    styles.swiper_container
                                                }
                                                swiperClassName={styles.swiper}
                                                items={whoIsPayload.info?.images?.all?.map(
                                                    (el) => (
                                                        <div
                                                            className={
                                                                styles.slide
                                                            }
                                                        >
                                                            <img
                                                                src={el}
                                                                alt="Corusel image"
                                                            />
                                                        </div>
                                                    )
                                                )}
                                            />
                                        )}
                                </div>
                                {whoIsPayload.info?.likes +
                                    whoIsPayload.info?.dislikes >
                                    0 && (
                                    <div className={styles.likes}>
                                        <span>
                                            Этот ответ оценили{' '}
                                            <b>
                                                {whoIsPayload.info?.likes +
                                                    whoIsPayload.info
                                                        ?.dislikes}{' '}
                                                раз
                                            </b>
                                            , из них{' '}
                                            <b>
                                                {whoIsPayload.info?.likes}{' '}
                                                положительно
                                            </b>
                                        </span>
                                        <span>
                                            <b>Изменено</b>{' '}
                                            {moment(
                                                whoIsPayload.info.updated
                                            ).format('DD.MM.YY HH:mm')}
                                        </span>
                                    </div>
                                )}
                                {whoIsPayload.info?.otherRecords?.length >
                                    0 && (
                                    <div className={styles.otherInfos}>
                                        <span>Другие ответы:</span>
                                        <div>
                                            {whoIsPayload.info?.otherRecords.map(
                                                (el, i) => (
                                                    <OtherVariant
                                                        key={i}
                                                        data={el}
                                                        // Смортрим, нажимают не на текущий, обновляем информацию
                                                        onClick={() =>
                                                            whoIsPayload.info
                                                                .id !== el.id &&
                                                            updateInfo(el.id)
                                                        }
                                                    />
                                                )
                                            )}
                                        </div>
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className={styles.error}>
                                Я ничего не нашел по твоему вопросу
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export { DispalyAnswer };
