import React, { useState } from 'react';

import { toClassNames } from '@helps/';
import {
    FiUser,
    FiEye,
    FiHeart,
    FiChevronDown,
    FiChevronUp,
} from 'react-icons/fi';

import styles from './style.module.scss';

const Info = ({ info = {}, likeAdding = false, onLike = () => {} }) => {
    const [fullDescription, setFullDescription] = useState(false);

    return (
        <div className={styles.info_container}>
            <div className={styles.title}>
                <span>{info.title}</span>
            </div>
            <div className={styles.stat_info}>
                <a
                    className={styles.author}
                    href={info.author_link}
                    target={'_blank'}
                >
                    <div className={styles.avatar}>
                        <FiUser size={35} color={'#006eff'} />
                    </div>
                    <span>{info.author_name}</span>
                </a>
                <div className={styles.stats}>
                    <div className={styles.stat}>
                        <FiEye className={styles.view} />
                        <span>
                            {info.view_count + ' '}
                            {info.service_view_count !== null && (
                                <span
                                    className={styles.from_service}
                                    title="В источнике"
                                >
                                    ({info.service_view_count})
                                </span>
                            )}
                        </span>
                    </div>
                    <div className={styles.stat}>
                        <FiHeart
                            className={toClassNames(
                                styles.like,
                                info.like_from_user && styles.like__selected,
                                likeAdding && styles.like__updating
                            )}
                            onClick={() => {
                                if (!likeAdding) onLike();
                            }}
                        />
                        <span>
                            {info.like_count + ' '}
                            {info.service_like_count !== null && (
                                <span
                                    className={styles.from_service}
                                    title="В источнике"
                                >
                                    ({info.service_like_count})
                                </span>
                            )}
                        </span>
                    </div>
                </div>
            </div>
            <div className={styles.description_container}>
                <span
                    className={toClassNames(
                        styles.description,
                        !fullDescription && styles.description__hide
                    )}
                >
                    {info.description}
                </span>
                <div
                    className={styles.full}
                    onClick={() => setFullDescription(!fullDescription)}
                >
                    {fullDescription ? (
                        <>
                            <span>Свернуть</span>
                            <FiChevronUp size={20} />
                        </>
                    ) : (
                        <>
                            <span>Развернуть</span>
                            <FiChevronDown size={20} />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export { Info };
