import React, { useMemo } from 'react'
import { Handle } from 'react-flow-renderer'

import { FiTrash2 } from 'react-icons/fi'

import styles from './style.module.css'

const StartDemonstration = ({
    onDragStart = () => { }
}) => {
    return (
        <div className={styles.container_demonstration} onDragStart={onDragStart} draggable>
            <p>Начало</p>
        </div>
    )
}

const dataInit = {
    onRemove: () => { }
}

const Start = ({
    id = '',
    data = {}
}) => {
    const _data = useMemo(() => ({ ...dataInit, ...data }), [data])

    return (
        <div className={styles.container}>
            <FiTrash2
                className={styles.icon}
                onClick={() => _data.onRemove(id)}
            />
            <p>Начало</p>
            <Handle type='source' position='right' id='out' className={styles.point_out} />
        </div>
    )
}

export { Start, StartDemonstration }