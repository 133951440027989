import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Loader } from '@c';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import styles from './style.module.scss';
import { toClassNames } from '@helps/';

const RuTubeCorusel = ({
    videos = [],
    selecedItem = 0,
    onChengeItem = () => {},
    className = '',
    style = {},
}) => {
    const [currentItem, setCurrentItem] = useState(selecedItem);
    const [loading, setLoading] = useState(true);

    const videoRef = useRef(null);

    const changeItem = useCallback(
        (index) => {
            if (!loading) {
                videoRef.current?.contentWindow?.postMessage(
                    JSON.stringify({
                        type: 'player:stop',
                        data: {},
                    }),
                    '*'
                );
                setLoading(true);
                setCurrentItem(index);
                selecedItem !== index && onChengeItem(index);
            }
        },
        [loading, videoRef]
    );

    useEffect(() => {
        selecedItem !== currentItem && changeItem(selecedItem);
    }, [selecedItem]);

    return (
        <div
            className={toClassNames(styles.container, className)}
            style={style}
        >
            <div className={styles.top}>
                <div
                    className={toClassNames(
                        styles.button_prev,
                        loading && styles.button_prev_loading
                    )}
                    onClick={() =>
                        currentItem - 1 < 0
                            ? changeItem(videos.length - 1)
                            : changeItem(currentItem - 1)
                    }
                >
                    <FiChevronLeft />
                </div>
                <div className={styles.video}>
                    <iframe
                        ref={videoRef}
                        src={`https://rutube.ru/pl/?pl_id&pl_type&pl_video=${videos[currentItem].id}`}
                        frameborder="0"
                        allow="clipboard-write"
                        webkitAllowFullScreen
                        mozallowfullscreen
                        allowfullscreen
                        onLoad={() => {
                            setLoading(false);
                            videoRef.current?.contentWindow?.postMessage(
                                JSON.stringify({
                                    type: 'player:stop',
                                    data: {},
                                }),
                                '*'
                            );
                        }}
                    />
                    {loading && (
                        <div className={styles.preloader}>
                            <Loader color="#006eff" className={styles.loader} />
                        </div>
                    )}
                </div>
                <div
                    className={toClassNames(
                        styles.button_next,
                        loading && styles.button_next_loading
                    )}
                    onClick={() =>
                        currentItem + 1 > videos.length - 1
                            ? changeItem(0)
                            : changeItem(currentItem + 1)
                    }
                >
                    <FiChevronRight />
                </div>
            </div>
            <div className={styles.pagination}>
                {videos.map((_, i) => (
                    <div
                        key={i}
                        className={
                            i !== currentItem
                                ? styles.pagination_item
                                : !loading
                                ? styles.pagination_item_selected
                                : styles.pagination_item
                        }
                        onClick={() => changeItem(i)}
                    >
                        {i === currentItem && <div />}
                    </div>
                ))}
            </div>
        </div>
    );
};

export { RuTubeCorusel };
