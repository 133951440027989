import { useState, useRef, useEffect } from 'react';

import { TTS } from '@helps/';
import { getCanceledPromise } from '@helps/CanceledPromise';

const useLoadAudioFile = (ref = null) => {
    const [dataPromiseCancel, setDataPromiseCancel] = useState({
        ...ref.current,
        canceled: false,
        cancel: () => {},
        finished: true,
    });
    const dataPromiseCancelRef = useRef(null);
    dataPromiseCancelRef.current = dataPromiseCancel;
    if (ref !== null) ref.current = dataPromiseCancel;

    /**
     * @callback callback
     * @param {Any} [props] - параметры
     * @returns {void}
     */

    /**
     * @typedef {object} TextObject
     * @property {String} text
     * @property {Object} options
     */

    /**
     * Получение ссылки на аудиофайл по передангому тексту
     * @param {String|TextObject} text - текст для перевода
     * @param {callback} beforeStart - что необходимо выполнить пред стартом
     * @param {callback} onUrl - когда появитя url
     * @param {import('@helps/CanceledPromise').Callbacks} promiseOptions
     */
    const getFileUrl = (
        text,
        beforeStart = () => {},
        onUrl = () => {},
        promiseOptions = {
            beforeStart: () => {},
            onCancel: () => {},
            onReject: () => {},
            onResolve: () => {},
        }
    ) => {
        beforeStart();

        let param;
        if (typeof text === 'object' && !Array.isArray(text)) {
            param = [text.text, text.options];
        } else if (typeof text === 'string') {
            param = [text];
        } else {
            const err = Error('Неверные параметры для озвучки');
            throw err;
        }

        const { promise, withCanceled, cancel } = getCanceledPromise(
            TTS(...param),
            {
                beforeStart: ({ cancel }) => {
                    setDataPromiseCancel({
                        canceled: false,
                        cancel,
                        finished: false,
                    });
                    promiseOptions.beforeStart();
                },
                onCancel: () => {
                    setDataPromiseCancel({
                        canceled: true,
                        cancel: () => {},
                        finished: true,
                    });
                    promiseOptions.onCancel();
                },
                onResolve: () => {
                    promiseOptions.onResolve();
                },
                onReject: () => {
                    setDataPromiseCancel({
                        canceled: false,
                        cancel: () => {},
                        finished: true,
                    });
                    promiseOptions.onReject();
                },
            }
        );

        promise
            .then(withCanceled((res) => window.URL.createObjectURL(res.data)))
            .then(withCanceled((res) => onUrl(res)))
            .then(
                withCanceled(() => {
                    setDataPromiseCancel({
                        canceled: false,
                        cancel: () => {},
                        finished: true,
                    });
                })
            )
            .catch((err) => console.error(1, err));

        return { promise, withCanceled, cancel };
    };

    return {
        state: dataPromiseCancelRef.current,
        getFileUrl,
    };
};

export { useLoadAudioFile };
