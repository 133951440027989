import React, { useEffect, useState, useRef } from 'react';

import { toClassNames } from '@helps';

import { FiTrash2 } from 'react-icons/fi';
import styles from './style.module.scss';

const AudioPlayerWithoutHook = ({
    audios = [],
    containerClassName = '',
    onDelete = null,
}) => {
    const audiosRef = useRef([]);
    const [_audios, setAudios] = useState();
    const [withDelete, setWithDelete] = useState(false);

    useEffect(() => {
        if (onDelete !== null && typeof onDelete === 'function') {
            setWithDelete(true);
        } else {
            setWithDelete(false);
        }
    }, [onDelete]);

    useEffect(() => {
        audiosRef.current = [];
        setAudios(() => {
            const tmp = audios.map((el, i) => (
                <div className={styles.audio} key={i}>
                    {withDelete && (
                        <div
                            className={styles.delete_button}
                            onClick={() => onDelete(i)}
                        >
                            <FiTrash2 size={25} />
                        </div>
                    )}
                    <span>{el.name}</span>
                    <audio
                        title={el.name}
                        ref={(ref) =>
                            ref !== null && audiosRef.current.push(ref)
                        }
                        src={el.src}
                        controls={true}
                        onPlay={() =>
                            audiosRef.current.forEach(
                                (el, idx) => i !== idx && el?.pause()
                            )
                        }
                    />
                </div>
            ));
            return tmp;
        });
    }, [audios, withDelete]);

    return (
        <div className={toClassNames(styles.audios, containerClassName)}>
            {_audios}
        </div>
    );
};

export { AudioPlayerWithoutHook };
