import React, { useState, useEffect } from 'react';

import { Quill } from '@c';
import { Input, AudioPlayer } from '../../components';
import { InfoBlock } from '../../InfoBlock';

import styles from './style.module.scss';

const Form = ({ data = {}, onChange = () => {}, uniq = '' }) => {
    const [_data, setData] = useState(data);

    useEffect(() => {
        onChange(_data);
    }, [_data]);

    return (
        <div className={styles.main}>
            <div className={styles.input_block}>
                <p>Текст</p>
                <Quill
                    uniq={uniq}
                    value={_data.text}
                    onChange={(value) =>
                        setData((prev) => ({ ...prev, text: value }))
                    }
                />
                {_data.speech && <AudioPlayer url={_data.speech} />}
                <InfoBlock />
            </div>
            <div className={styles.input_block}>
                <p>Изображения</p>
                <Input
                    type="images"
                    value={_data.images}
                    onChange={(images) =>
                        setData((prev) => ({ ...prev, images }))
                    }
                    imagesClassName={styles.images}
                    imageClassName={styles.image}
                    iconClassName={styles.image_icon}
                    buttonsClassName={styles.buttons}
                    buttonClassName={styles.button}
                />
            </div>
            <div className={styles.input_block}>
                <p>Видео</p>
                <Input
                    type="videos"
                    value={_data.videos}
                    onChange={(videos) =>
                        setData((prev) => ({ ...prev, videos }))
                    }
                    videosClassName={styles.images}
                    videoClassName={styles.image}
                    iconClassName={styles.image_icon}
                    frameClassName={styles.youtube}
                    buttonsClassName={styles.buttons}
                    buttonClassName={styles.button}
                />
            </div>
            <div className={styles.input_block}>
                <p>Аудиозаписи</p>
                <Input
                    type="audios"
                    value={_data.audios}
                    onChange={(audios) =>
                        setData((prev) => ({ ...prev, audios }))
                    }
                    audiosClassName={styles.audios}
                    audioClassName={styles.audio}
                    iconClassName={styles.audio_icon}
                    buttonClassName={styles.button}
                    buttonsClassName={styles.buttons}
                />
            </div>
        </div>
    );
};

export { Form };
