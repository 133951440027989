import React from 'react';

import { formatBytesCount } from '@helps/';

import { FileIcon, defaultStyles } from 'react-file-icon';
import { FiDownload } from 'react-icons/fi';

import styles from './style.module.scss';

const FileSelector = ({ files = [], themeName = '' }) => {
    const download = (url, name) => {
        if (/\.pdf$/gm.test(name)) {
            const a = document.createElement('a');
            a.href = url;
            a.target = '_blank';
            a.click();
        } else {
            fetch(url, { credentials: 'include' })
                .then((res) => {
                    return res.blob();
                })
                .then((blob) => {
                    const a = document.createElement('a');
                    a.href = window.URL.createObjectURL(blob);
                    a.download = name;
                    a.click();
                })
                .catch((err) => console.error(err));
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <p>Список файлов по теме &laquo;{themeName}&raquo;:</p>
            </div>
            <div className={styles.files_container}>
                {files.length === 0 ? (
                    <div className={styles.empty}>
                        <span>
                            Электронные материалы по данной теме отсутствуют
                        </span>
                    </div>
                ) : (
                    files.map((el, i) => {
                        const tmp = /\.(\w+)$/.exec(el.name);
                        const extension = tmp
                            ? tmp[1].toLocaleLowerCase()
                            : null;

                        const props = {
                            extension: extension || undefined,
                            type:
                                extension === null ? 'spreadsheet' : undefined,
                        };

                        return (
                            <div className={styles.row} key={i}>
                                <div className={styles.file_icon}>
                                    <FileIcon
                                        {...props}
                                        {...defaultStyles[extension]}
                                    />
                                </div>
                                <div className={styles.file_name}>
                                    <span>{el.name}</span>
                                    <div className={styles.options}>
                                        {el.teacher && (
                                            <span>
                                                Загружено: {el.teacher.lastName}{' '}
                                                {el.teacher.firstName[0]}.{' '}
                                                {el.teacher.patronymic[0]}.
                                            </span>
                                        )}
                                        {el.uploadAt && (
                                            <span>Дата: {el.uploadAt}</span>
                                        )}
                                        {el.size && (
                                            <span>
                                                Размер:{' '}
                                                {formatBytesCount(
                                                    parseInt(el.size)
                                                )}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className={styles.download}>
                                    <FiDownload
                                        color="#006eff"
                                        size={30}
                                        onClick={() =>
                                            download(
                                                // `${process.env.REACT_APP_API_URL}/lms/?action=teacherplanfile&schedule=${currentElectronicMaterialsTheme.scheduleId}&week=${currentElectronicMaterialsTheme.weekId}&file=${el.name}`,
                                                el.downloadURI,
                                                el.name
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        );
                    })
                )}
            </div>
        </div>
    );
};

export { FileSelector };
