import React, { useState, useEffect } from 'react';
import { TreeView, TreeItem } from '@mui/lab';
import { useSelector } from 'react-redux';
import { FiLock } from 'react-icons/fi';

import { teacherActions } from '@r/teacher/teacherSlice';
import { useActions } from '@h';
import { toClassNames } from '@helps';

import styles from './style.module.scss';

function courseItems(arr = [], func = () => {}, func2 = () => {}) {
    let n = arr.length;
    let content = [];
    for (let i = 0; i < n; i++) {
        if (arr[i].children && arr[i].children.length > 0) {
            content.push(
                <TreeItem
                    nodeId={`${arr[i].oid}`}
                    key={arr[i].oid}
                    label={arr[i].title}
                >
                    {courseItems(arr[i].children, func, func2)}
                </TreeItem>
            );
        } else
            content.push(
                <div className={styles.item} key={arr[i].oid}>
                    <TreeItem
                        nodeId={`${arr[i].oid}`}
                        label={'- ' + arr[i].title}
                        onClick={() => {
                            func(arr[i].oid);
                            func2(arr[i].title);
                        }}
                    />
                </div>
            );
    }
    return content;
}

export default function CourseMenu() {
    const [selectValue, setSelectValue] = useState(undefined);
    const [menuActive, setMenuActive] = useState(false);
    const [fixed, setFixed] = useState(false);

    const { periodsStart, coursesStart, schemeStart, setCourseName } =
        useActions(teacherActions);
    const { periods, courses } = useSelector(({ teacher }) => teacher);

    useEffect(() => {
        periodsStart();
    }, [periodsStart]);

    useEffect(() => {
        coursesStart(selectValue);
    }, [selectValue, coursesStart]);

    let resPeriods = [];

    periods.filter(function (item) {
        let i = resPeriods.findIndex((x) => x.Year === item.Year);
        if (i <= -1) {
            resPeriods.push(item);
        }
        return null;
    });

    return (
        <div
            className={toClassNames(
                styles.main,
                menuActive | fixed ? styles.active : undefined
            )}
        >
            <div
                className={styles.course_menu}
                onMouseLeave={() => setMenuActive(false)}
                onMouseEnter={() => setMenuActive(true)}
            >
                <div className={styles.year}>
                    <div className={styles.title}>Выберите учебный год:</div>
                    <select onChange={(e) => setSelectValue(e.target.value)}>
                        {resPeriods.map((item, i) => (
                            <option value={item.Year} key={i}>
                                {item.YearName}
                            </option>
                        ))}
                    </select>
                </div>
                {courses && courses.length ? (
                    <div className={styles.list}>
                        <h1 className={styles.title}>Темы для занятий:</h1>
                        <div className={styles.block}>
                            <TreeView>
                                {courseItems(
                                    courses,
                                    schemeStart,
                                    setCourseName
                                )}
                            </TreeView>
                        </div>
                    </div>
                ) : undefined}
            </div>
            <div
                className={styles.marker}
                onMouseLeave={() => setMenuActive(false)}
                onMouseEnter={() => setMenuActive(true)}
            >
                <div className={styles.fix} onClick={() => setFixed(!fixed)}>
                    {fixed && <FiLock size={17} />}
                </div>
            </div>
        </div>
    );
}
