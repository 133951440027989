import React, { useContext, useEffect, useState } from 'react';

import { AleshaHeadContext } from '@p/Home/utils';
import { toClassNames } from '@helps/classNames';

import head_talking from '../../img/head_talking_vp9.webm';
import head_idle from '../../img/head_idle_vp9.webm';

import styles from './style.module.scss';

const AleshasHead = ({
    aleshaSpeaking = undefined,
    headData = undefined,
    className,
}) => {
    const [speacking, setSpeaking] = useState(false);
    const [_headData, setHeadData] = useState({});

    const { aleshaSpeaking: aleshaSpeakingContext, headData: headDataContext } =
        useContext(AleshaHeadContext);

    useEffect(() => {
        if (aleshaSpeaking === undefined) setSpeaking(aleshaSpeakingContext);
        else setSpeaking(aleshaSpeaking);
    }, [aleshaSpeaking, aleshaSpeakingContext]);

    useEffect(() => {
        if (headData) return;
        if (headDataContext && headDataContext.name) {
            setHeadData(headDataContext);
        } else setHeadData(undefined);
    }, [headData, headDataContext]);

    useEffect(() => {
        setHeadData(headData);
    }, [headData]);

    return (
        <div className={toClassNames(styles.container, className)}>
            <video
                src={_headData ? `/media/${_headData.talking}` : head_talking}
                type="video/webm"
                autoPlay
                muted
                loop
                className={styles.head}
                style={{
                    display: speacking ? 'block' : 'none',
                }}
            />
            <video
                src={_headData ? `/media/${_headData.idle}` : head_idle}
                type="video/webm"
                autoPlay
                muted
                loop
                className={styles.head}
                style={{ display: !speacking ? 'block' : 'none' }}
            />
        </div>
    );
};

export { AleshasHead };
