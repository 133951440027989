import React, { useState } from 'react';

import { Quill, Button2 } from '@c';

const Solution = ({
    value = '',
    onChange = () => {},
    buttonClassName = '',
    uniq = ''
}) => {
    const [visable, setVisable] = useState(value.length !== 0);

    return (
        <>
            {visable ? (
                <div>
                    <Quill
                        uniq={`solution_${uniq}`}
                        value={value}
                        onChange={onChange}
                    />
                </div>
            ) : (
                <Button2
                    title="Добавить решение"
                    className={buttonClassName}
                    onClick={() => setVisable(true)}
                />
            )}
        </>
    );
};

export { Solution };
