import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';

import AuthForm from './AuthForm/AuthForm';
import { AuthByFace } from './AuthByFace';
import { ViewContext } from './utils';

import { appVersion } from '@consts/version';

import styles from './Auth.module.css';
import AuthByFinger from './AuthByFinger';

export const Auth = () => {
    const { data: authData } = useSelector(({ auth }) => auth);

    const [webcamAuth, setWebcamAuth] = useState(false);
    const [fingerAuth, setFingerAuth] = useState(false);
    const [loginPassAuth, setLoginPassAuth] = useState(false);
    const [alert, setAlert] = useState({});
    const [alertTimer, setAlertTimer] = useState(null);
    const [fingerError, setFingerError] = useState({username: '', count: 3, state: false})


    useEffect(() => {
        if (alert.show) {
            if (alertTimer) clearTimeout(alertTimer);
            setAlertTimer(
                setTimeout(
                    () => setAlert((prev) => ({ ...prev, show: false })),
                    5000
                )
            );
        }
    }, [alert]);

    useEffect(() => {
        return () => {
            setAlert({});
            alertTimer && clearTimeout(alertTimer);
        };
    }, []);

    useEffect(() => {
      if (fingerError.username) {
        setLoginPassAuth(false)
        setFingerAuth(true)
    } else if (!fingerError.count && !fingerError.state) {
        setLoginPassAuth(true)
        setFingerAuth(false)
        setFingerError(prev => ({...prev, state: true}))
      }
    }, [fingerError])
    
    
    useEffect(() => {
      if (authData) {
        setFingerError(prev => ({...prev, username: authData.username, schoolID: authData.schoolID}))
      }
    }, [authData])
        
    if (authData && !fingerError.state) return <Redirect to="/" />;

    return (
        <div className={styles.wrapper}>
            <div className={styles.webcam}>
                <ViewContext.Provider
                    value={{
                        setLoginPassAuth,
                        setWebcamAuth,
                        setFingerAuth,
                        alert,
                        setAlert,
                        alertTimer,
                        setAlertTimer,
                        fingerError,
                        setFingerError
                    }}
                >
                    {!loginPassAuth && !webcamAuth && !fingerAuth && (
                        <div className={styles.auth_buttons}>
                            <div>
                                <button
                                    className={styles.btn}
                                    onClick={() => setWebcamAuth(true)}
                                >
                                    Авторизация по лицу
                                </button>
                                <button
                                    className={styles.btn}
                                    onClick={() => setLoginPassAuth(true)}
                                >
                                    Авторизация по логину
                                </button>
                                <button
                                    className={styles.btn}
                                    onClick={() => setFingerAuth(true)}
                                >
                                    Авторизация по отпечатку пальца
                                </button>
                            </div>
                        </div>
                    )}
                    {loginPassAuth && <AuthForm />}
                    {fingerAuth && <AuthByFinger addMode={fingerError.state}/>}
                    {webcamAuth && <AuthByFace />}
                </ViewContext.Provider>
            </div>
            <div className={styles.footer}>{appVersion}</div>
        </div>
    );
};
