import React from 'react';

import * as AnswerTypes from './AnswerTypes';

const questionTypes = [
    {
        title: 'С вариантами ответа',
        name: 'multi',
    },
    {
        title: 'Совпадение',
        name: 'concurrence',
    },
    {
        title: 'Точное совпадение',
        name: 'accurate',
    },
    {
        title: 'Положительный/Отрицательный',
        name: 'yesNo',
    },
];

const QuestionAnswer = (props) => {
    return React.createElement(AnswerTypes[props.type], props);
};

const QuestionTypesInfo = ({ className }) => {
    return (
        <div className={className}>
            Существует три типа вопросов:
            <ul>
                <li>
                    <b>С вариантами ответа</b> - Вы вводите несколько вариантом
                    ответа и выбирается из них правильные (верных ответом может
                    быть от одного до всех ваших вариантов)
                </li>
                <li>
                    <b>Совпадение</b> - Вы вводите верный ответ, ученик при
                    прохождении вопроса даетответ и, если совпадение по тексту
                    более 60%, ответ считается верным (правльность ответа
                    ученика не гарантируется)
                </li>
                <li>
                    <b>Точное совпадение</b> - Это может быть одно слово или
                    словосочетание, проверяется полное совпадение (регистр и
                    пробелы не учитываются)
                </li>
                <li>
                    <b>Положительный/Отрицательный</b> - Верный ответ - (Да,
                    Конечно, хорошо и т.д.), остальное - неверный
                </li>
            </ul>
        </div>
    );
};

export { questionTypes, QuestionAnswer, QuestionTypesInfo };
