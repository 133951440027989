import { api } from "../App";

export const getPeriods = () => api.get(`/courses`);

export const getCourses = (yearId = 14) => {
  return api.get(`/courses/structure?year=${yearId}`);
};

export const getScheme = (courseId) => {
  return api.get(`/courses/${courseId}/schemes/`);
};

export const saveScheme = ({ data, schemeId }) => {
  return api.put(`/courses/${schemeId}/schemes/`, { data });
};
