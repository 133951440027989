import React, { useEffect, useRef, useImperativeHandle, useState } from 'react';

import WebCam from 'react-webcam';
import { detectFaces, drawResult, loadModels } from './utils';

import styles from './style.module.css';
import { useCallback } from 'react';

loadModels();

const EmotionCamera = React.forwardRef(({ onFaceDetected = () => {} }, ref) => {
    const webCamRef = useRef(null);
    const canvasRef = useRef(null);
    const _faceCanvasRef = useRef(null);

    const [result, setResult] = useState(null);
    const [faceDetected, setFaceDetected] = useState(false);

    const clearOverlay = (canvasRef) => {
        if (canvasRef.current) {
            canvasRef.current
                .getContext('2d')
                .clearRect(
                    0,
                    0,
                    canvasRef.current.width,
                    canvasRef.current.height
                );
        }
    };

    const getFaces = async () => {
        if (webCamRef.current && canvasRef.current) {
            try {
                const faces = await detectFaces(webCamRef.current.video);
                setResult(faces);
                await drawResult(
                    webCamRef.current.video,
                    canvasRef.current,
                    faces,
                    'boxLandmarks'
                );

                // await drawResult(webCamRef.current.video, canvasRef.current, faces, 'box')
            } catch (error) {
                console.info(error);
            }
        }
    };

    useEffect(() => {
        if (!faceDetected && result?.length > 0) {
            onFaceDetected();
            setFaceDetected(true);
        }
    }, [result]);

    const getFace = useCallback(() => {
        try {
            if (result) {
                // const box = Object.create(result[0].detection.box)
                const box = Object.create(result[0].box);
                _faceCanvasRef.current.width = box.width + 20;
                _faceCanvasRef.current.height = box.height + 20;
                _faceCanvasRef.current
                    .getContext('2d')
                    .drawImage(
                        webCamRef.current.video,
                        box.x - 40,
                        box.y - 60,
                        box.width + 20,
                        box.height + 20,
                        0,
                        0,
                        box.width + 20,
                        box.height + 20
                    );
                return _faceCanvasRef.current.toDataURL('image/jpeg', 1);
            }
        } catch (e) {
            return null;
        }
    }, [result, webCamRef, _faceCanvasRef]);

    useImperativeHandle(ref, () => ({
        video: webCamRef.current,
        getFace,
        faceDetected,
    }));

    useEffect(() => {
        let interval = null;

        if (webCamRef.current !== null) {
            interval = setInterval(async () => {
                await getFaces();
            }, 200);
        }

        return () => {
            clearOverlay(canvasRef);
            clearInterval(interval);
        };
    }, [webCamRef, canvasRef]);

    return (
        <>
            <div className={styles.root}>
                <WebCam
                    audio={false}
                    ref={webCamRef}
                    width="100%"
                    height="auto"
                />
                <canvas ref={canvasRef} className={styles.overlay} />
            </div>
            <canvas
                id="face"
                ref={_faceCanvasRef}
                className={styles.overlay_test}
            />
        </>
    );
});

export { EmotionCamera };
