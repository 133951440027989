import React, { useContext } from 'react';

import { DialogContext } from '../utils';
import { Input } from './Input';

import styles from './Dialog.module.scss';

const Dialog = () => {
    const { currentCommand, instantSetValue } = useContext(DialogContext);

    return (
        <div className={styles.wrapper}>
            <div className={styles.dialog_wrapper}>{currentCommand}</div>
            <Input onEnter={() => instantSetValue()} />
        </div>
    );
};

export { Dialog };
