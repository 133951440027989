/**
 * Отчистка списка от пустых тем
 * @param {Array<Object>} themes
 * @param {null|Number} courseId
 * @returns {Array<Object>}
 */
const filterThemes = (themes, courseId = null) => {
    if (!Array.isArray(themes)) {
        throw new Error('First argument is not array');
    }
    if (themes.length === 0) return themes;

    let element = null;
    let tmp = themes.filter((el) => {
        if (courseId === 266 && el.id === 217268) {
            element = el;
            return false;
        }
        return !!el.theme;
    });

    if ((courseId === 266, !!element)) {
        return [element, ...tmp];
    } else {
        return tmp;
    }
};

export { filterThemes };
