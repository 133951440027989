import { call, put, takeLatest } from 'redux-saga/effects';

import { testsActions } from './testsSlice';
import {
    getPerceptionType as getPerceptionTypeRequest,
    getNewPerceptionText as getNewPerceptionTextRequest,
} from '../../api/tests';

const {
    getPerceptionType,
    setPerceptionType,
    toggleLoading,
    // togglePerсeptionTypeTextLoaded,
    getNewPerceptionText,
    setNewPerceptionTest,
} = testsActions;

function* testsWatcher() {
    yield takeLatest(getPerceptionType.type, getPerceptionTypeWorker);
    yield takeLatest(getNewPerceptionText.type, getNewPerceptionTextWorker);
}

function* getPerceptionTypeWorker() {
    yield put(toggleLoading(true));
    // yield put(togglePerсeptionTypeTextLoaded(false))
    try {
        const response = yield call(getPerceptionTypeRequest);
        if (response.status === 200) {
            yield put(setPerceptionType(response.data));
        }
    } catch (error) {
        yield console.error(error);
    } finally {
        // yield put(togglePerсeptionTypeTextLoaded(true))
        yield put(toggleLoading(false));
    }
}

function* getNewPerceptionTextWorker({ textIndex }) {
    yield put(toggleLoading(true));
    // yield put(togglePerсeptionTypeTextLoaded(false))
    try {
        const response = yield call(getNewPerceptionTextRequest, textIndex);
        if (response.status === 200) {
            yield put(setNewPerceptionTest(response.data));
        } else {
            throw response.request;
        }
    } catch (e) {
        yield console.error(e);
    } finally {
        yield put(toggleLoading(false));
        // yield put(togglePerсeptionTypeTextLoaded(true))
    }
}

export { testsWatcher };
