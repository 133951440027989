import * as faceapi from 'face-api.js';

/**
 * Загрузка моделей
 * @returns
 */
const loadModels = () => {
    const MODEL_URL = `${process.env.PUBLIC_URL}/models`;

    return Promise.all([
        faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
        // faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
        faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
        // faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
        // faceapi.nets.ageGenderNet.loadFromUri(MODEL_URL)
    ]);
};

/**
 * Поиск лиц на изображении
 * @param {Element} image - кадр с видео
 * @returns
 */
const detectFaces = async (image) => {
    try {
        if (!image) return;

        const imgSize = image.getBoundingClientRect();
        const displaySize = { width: imgSize.width, height: imgSize.height };

        if (displaySize.height === 0) return;

        const faces = await faceapi.detectAllFaces(
            image,
            new faceapi.TinyFaceDetectorOptions({ inputSize: 320 })
        );
        // .withFaceLandmarks()
        // .withFaceExpressions()
        // .withAgeAndGender()
        // .withFaceDescriptors()

        return faceapi.resizeResults(faces, displaySize);
    } catch (e) {
        console.error(e);
    }
};

/**
 *
 * @param {HTMLImageElement} image
 * @param {HTMLCanvasElement} canvas
 * @param {object} result
 * @param {*} type
 */
const drawResult = async (image, canvas, result, type) => {
    try {
        if (image && canvas && result) {
            const imageSize = image.getBoundingClientRect();
            // console.log(imageSize);
            const displaySize = {
                width: imageSize.width,
                height: imageSize.height,
            };
            faceapi.matchDimensions(canvas, displaySize);
            const context = canvas.getContext('2d');
            context.clearRect(0, 0, canvas.width, canvas.height);
            const resizedDetections = faceapi.resizeResults(
                result,
                displaySize
            );

            // const _faceCanvasRef = document.getElementById('face')
            // const box = result[0].box
            // _faceCanvasRef.width = box.width + 20
            // _faceCanvasRef.height = box.height + 20
            // _faceCanvasRef.getContext('2d').drawImage(image, box.x - 40, box.y - 60, box.width + 20, box.height + 20, 0, 0, box.width + 20, box.height + 20);

            switch (type) {
                case 'landmarks':
                    faceapi.draw.drawFaceLandmarks(canvas, resizedDetections);
                    break;
                case 'expressions':
                    faceapi.draw.drawFaceExpressions(canvas, resizedDetections);
                    break;
                case 'box':
                    faceapi.draw.drawDetections(canvas, resizedDetections);
                    break;
                case 'boxLandmarks':
                    const box = Object.create(result[0].box);
                    // console.log(box);

                    context.beginPath();
                    context.rect(
                        box.x - 20,
                        box.y - 80,
                        box.width + 40,
                        box.height + 80
                    );
                    context.lineWidth = 2.5;
                    context.strokeStyle = '#0077ff';
                    context.stroke();
                    // .drawImage(
                    // webCamRef.current.video,
                    // box.x - 40,
                    // box.y - 60,
                    // box.width + 20,
                    // box.height + 20,
                    // 0,
                    // 0,
                    // box.width + 20,
                    // box.height + 20
                    // );
                    // faceapi.draw.drawDetections(canvas, resizedDetections);

                    // faceapi.draw.drawFaceExpressions(canvas, resizedDetections)
                    // faceapi.draw.drawFaceLandmarks(canvas, resizedDetections)
                    break;
                default:
                    break;
            }
        }
    } catch (e) {
        console.error(e);
    }
};

export { loadModels, detectFaces, drawResult };
