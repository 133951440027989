import { useActions } from '@h/useActions';
import { authActions } from '@r/auth/authSlice';
import React, { useContext, useEffect, useState } from 'react'
import { IoFingerPrint } from 'react-icons/io5';
import styles from './style.module.scss';
import { FiArrowLeft } from 'react-icons/fi';
import { ViewContext } from '../utils';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { FINGER_SOCKET_URL } from '@consts/api';


export default function AuthByFinger ({ addMode=true }) {
  

  const { setFingerAuth, fingerError, setFingerError } = useContext(ViewContext);
  const { loginByFingerStart, toggleError } = useActions(authActions);
  const { error } = useSelector(state => state.auth)
  const { enqueueSnackbar } = useSnackbar();
  const [socket, setSocket] = useState(null)

  useEffect(() => {
    const _socket = new WebSocket(FINGER_SOCKET_URL);
    setSocket(_socket)
    return () => {
      _socket.close()
    }
  }, [])
  

  useEffect(() => {
    if (socket) {
      socket.onmessage = (event) => {
        const data = JSON.parse(event.data)
        if (data.login) {
          const finger_login = data.login
          const finger_schoolid = data.schoolid   
          const finger_token = data.token       
          fingerAuthStart({finger_login, finger_schoolid, finger_token})
        } else if (data.message && data.status) {
          setFingerAuth(false)
          setFingerError({username: '', count: 3, state: false})
          createNotification('success', 'Отпечаток успешно добавлен')
        } else if (data.error) {
          const newTryCount = fingerError.count-1
          newTryCount && !addMode && createNotification('error', `Неверный отпечаток. Осталось попыток: ${newTryCount}`)
          setFingerError(prev => ({...prev, count: newTryCount}))
        } else if (data.status==='INITIATING' && addMode) {          
          socket.send(JSON.stringify({message: 'add user', login: fingerError.username, schoolID: fingerError.schoolID}))
          createNotification('warning', `Приложите палец и удерживайте до 3 двойных сигналов`)
        }
      };
    }
  }, [socket, fingerError])

  useEffect(() => {
    if (error) {
      const svg = document.getElementById('svgIcon')
      svg.classList.add(styles.redColor)
      svg.classList.remove(styles.greenColor)
      setTimeout(() => {
        svg.classList.remove(styles.redColor)
        toggleError(false);
      }, 1500);
    }
  }, [error])
    
  const createNotification = (type, text) => enqueueSnackbar(
    text,
    {
        variant: type,
        anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
        },
    }
  )

  const fingerAuthStart = (payload) => {
    loginByFingerStart(payload)
    const svg = document.getElementById('svgIcon')
    svg.classList.add(styles.greenColor)
  }

  const backButtonClick = (e) => {
    setFingerAuth(false)
    setFingerError({username: '', count: 3, state: false})
    error && toggleError(false);
  }

  return (
        <div className={styles.fingerBlock}>
            <div
                className={styles.back}
                onClick={backButtonClick}
            >
                <FiArrowLeft size={30} color="white" />
                <span>{addMode ? 'Отмена' : 'Назад'}</span>
            </div>
            {addMode && <h1>Добавление отпечатка:</h1>}
            <IoFingerPrint
                id='svgIcon'
                size={300}
                className={styles.svg}
            />
        </div>
  )
}
