import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { useActions } from '@h/useActions';
import video from '@img/background.mp4';
import { authActions } from '@r/auth/authSlice';
import { getAutologout } from './utils';

import { Header } from './Header';
import styles from './Layout.module.css';
import { toClassNames } from '@helps/classNames';

export const Layout = ({ children }) => {
    const history = useHistory();

    const authData = useSelector(({ auth }) => auth.data);
    const { loginCheck, resetAuthData } = useActions(authActions);

    const [toLogin] = useState(getAutologout());

    useEffect(() => {
        if (authData === undefined) {
            if (history.location.pathname !== '/auth') {
                if (toLogin) {
                    resetAuthData();
                    history.push('/auth');
                } else {
                    loginCheck();
                }
            } else {
                resetAuthData();
            }
        }
    }, [authData, history]);

    useEffect(() => {
        if (authData !== undefined) {
            if (authData === null) {
                history.push('/auth');
                return;
            }
            if (authData.type !== 'student') {
                resetAuthData();
                return;
            }
        }
    }, [authData, history]);

    return (
        authData !== undefined && (
            <div className={toClassNames(!authData && styles.layout)}>
                {authData && (
                    <video autoPlay muted loop className={styles.myVideo}>
                        <source src={video} type="video/mp4" />
                    </video>
                )}

                <div className={styles.container}>
                    <Header />
                    {children}
                    {/* <Footer /> */}
                </div>
            </div>
        )
    );
};
