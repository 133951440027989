import React, {
    createContext,
    useEffect,
    useState,
    useContext,
    useCallback,
} from 'react';
import { useSelector } from 'react-redux';

import {
    useSocket,
    useSTT,
    useCompare,
    useTextChangeEnd,
    useActions,
} from '@h';
import { AleshaHeadContext, ViewContext } from './';
import { useCommands } from './useCommands';

import { profileActions } from '@r/profile/profileSlice';

const DialogContext = createContext({
    value: '',
    setValue: () => {},
    resetText: () => {},
    record: false,
    setRecord: () => {},
    translated: {},
    translatedText: '',
    setTextCleanup: () => {},
    setCheckCommands: () => {},
    compareTexts: () => {},
    results: [],
    setSelectedCommand: () => {},
    currentCommand: '',
    instantSetValue: () => {},
    onStageChange: () => {},
    setCheckFullText: () => {},
    onTestFinish: () => {},
    clean: () => {},
});

const DialogContextProvider = ({ children }) => {
    // Создаем сокет

    const { socket, initSocket, disconnectSocket } = useSocket();

    const { setAleshaSpeaking, aleshaSpeaking } = useContext(AleshaHeadContext);
    const { view, COMPONENT_NAME } = useContext(ViewContext);

    console.log(socket)

    // Создаем соединение для stt
    const {
        record,
        setRecord,
        translated,
        translatedText,
        resetText,
        closeRecord,
    } = useSTT(socket);
    // Создаем соединение для сравнения текстов
    const { results, compareTexts } = useCompare(socket);

    // const setRecord = (val) => {
    //     setRec(val);
    //     console.warn(new Error(`change micro [${val}]`));
    // };

    // Работа с командами
    const {
        commandsList,
        currentView,
        setCurrentViewName,
        notCommandsCheckedList,
        commandsListWithSubstitution,
    } = useCommands(setRecord, { resetText });

    const selectedCommand = useSelector(
        ({ profile: { selectedCommand } }) => selectedCommand
    );

    // Тексты для сравления
    const [textToCompare, setTextToCompare] = useState(commandsList);
    // Вызов сравнения текстов
    const _compareText = () =>
        compareTexts({ textOne: textToCompare, textTwo: value });
    // Значение инпута
    /**@type {[String, (str: String) => void, () => void]} */
    const [value, setValue, instantSetValue] = useTextChangeEnd(_compareText);
    
    // Установка значения в редакс
    const { changeSelectedCommand } = useActions(profileActions);

    // Для отчистки текста от предыдущих значений
    /**@type {[null|RegExp, (val: null|RegExp)]} */
    const [cleaner, setCleaner] = useState(null);
    const [cleanerTimeout, setCleanerTimeout] = useState(null);

    useEffect(() => {
        console.log(cleaner);
    }, [cleaner]);

    /**
     * Отчистка текста от слов, переданных в массиве
     * @param {Array<String>|null|RegExp} strs
     */
    const clean = (strs, time = 10000) => {
        const _setTimeout = () => {
            // Если передан кторой параметр, устанавливаем таймер
            if (time !== null) {
                setCleanerTimeout((prev) => {
                    if (prev) clearTimeout(prev);
                    return setTimeout(() => {
                        setCleaner(null);
                        setCleanerTimeout(null);
                    }, time);
                });
            }
        };

        // если null сбрасываем очистку
        if (strs === null) {
            setCleaner(null);
            cleanerTimeout &&
                setCleanerTimeout((prev) => (clearTimeout(prev), null));
            return;
        }

        // установка регулрки
        if (strs instanceof RegExp) {
            setCleaner(strs);
            _setTimeout();
            return;
        }

        if (Array.isArray(strs) && strs.length) {
            setCleaner(new RegExp(strs.join('|'), 'gmi'));
            _setTimeout();
            return;
        }

        console.warn(new Error(`Передано неверное выражение ${strs}`));
    };

    useEffect(() => {
        // Создаем сокет
        initSocket();
        // Устанавливаем начальный экан
        changeSelectedCommand('Приветствие');

        return () => {
            closeRecord();
            disconnectSocket();
            // Отключаем сокет
            // setRecord(false);
            // closeRecord();
            // di sconnectSocket();
        };
    }, []);

    // Проверка команд
    const [checkCommands, setCheckCommands] = useState(true);
    // Если устанавливатся проверка команд, команды сравнимаются
    // со значением инпута
    useEffect(() => {
        if (checkCommands) setTextToCompare(commandsList);
    }, [checkCommands]);

    // Таймер изменения значения инпута
    const [valueChangeTimer, setValueChangeTimer] = useState(null);
    // Отчистка инпута
    const [textCleanup, setTextCleanup] = useState(true);
    // Следующая команда
    const [nextView, setNextView] = useState('');
    // Оставлять распознанный текст или удалять
    const [checkFullText, setCheckFullText] = useState(true);
    //
    const [checkingText, setCheckingText] = useState(true);
    // переход к следующей команду
    // console.log(nextView, currentView?.type?.name, checkCommands, value);
    const toNextView = useCallback(() => {
        if (nextView) {
            setCheckingText(false);
            setTimeout(() => setCheckingText(true), 2000);

            // Очистка от прерыдущего значения инпута
            clean([value]);
            if (nextView) setCheckCommands(true);
            // Установка новой команды
            setCurrentViewName(nextView, { text: value });
            // Установка следующей команды
            setNextView(null);
            setAleshaSpeaking(false);
            setValue('');
            resetText();
            return;
        }
    }, [value, nextView]);
    useEffect(() => {
        // если микровон отключен, выходим
        if (!record) return;
        // Обновляем таймер
        if (checkingText && textCleanup && valueChangeTimer) {
            clearTimeout(valueChangeTimer);
            setValueChangeTimer(null);
        }

        if (translated) {
            if (checkFullText) {
                // Берем полный текст
                setValue(
                    // Если есть регулярка, очищаем по ней
                    cleaner
                        ? translatedText
                              .replace(cleaner, '')
                              // Удаляем лишние пробелы
                              .replace(/\s{2,}/gm, '')
                        : translatedText
                );
            } else {
                // Берем только текст котороый пришел в данный момент
                setValue(
                    cleaner
                        ? translated?.alternatives[0].text
                              .replace(cleaner, '')
                              .replace(/\s{2,}/gm, '')
                        : translated?.alternatives[0].text
                );
            }
            if (textCleanup) {
                // Устанавливаем новый таймер
                setValueChangeTimer(
                    setTimeout(() => {
                        toNextView();
                        setValue('');
                        resetText();
                    }, 3500)
                );
            }
        }
    }, [translated]);
    useEffect(() => {
        // при вове руками текст не очищается, поэтому очищаем
        if (cleaner) {
            setValue((prev) =>
                prev.replace(cleaner, '').replace(/\s{2,}/gm, '')
            );
        }

        // Сбрасываем таймер
        if (checkingText && textCleanup && valueChangeTimer) {
            clearTimeout(valueChangeTimer);
            setValueChangeTimer(null);
        }

        // Обновляем теймер
        if (value) {
            if (textCleanup) {
                setValueChangeTimer(
                    setTimeout(() => {
                        toNextView();
                        setValue('');
                        resetText();
                    }, 3500)
                );
            }
        }
    }, [value, nextView]);

    // При изменении textCleanup сбрасываем таймер
    useEffect(() => {
        valueChangeTimer && clearTimeout(valueChangeTimer);
    }, [textCleanup]);

    // результаты сравнения
    useEffect(() => {
        // если текщий экран - экарн элетроннного занятия, не сравнимаем
        if (view === COMPONENT_NAME.ELECTRINIC_LESSON) return;

        // Если есть команды с вероятностью 70%, заходим в if
        const tmp = value.toLowerCase();
        const checkSubs = commandsListWithSubstitution
            .map((el) => tmp.indexOf(el) === 0)
            .filter((el) => el === true);
        const checkResults = results.filter((el) => el >= 0.7).length > 0;
        if (
            tmp.trim() !== '' &&
            checkingText &&
            checkCommands &&
            (checkResults || checkSubs.length > 0)
        ) {
            // Определяем что за команды
            const command = commandsList[results.indexOf(Math.max(...results))];
            // Если команда со *, устанавливаем экран загрузки
            // и отменяем дальнейшие проверки
            if (checkSubs.length > 0 && !checkResults) {
                setCheckCommands(false);
                changeSelectedCommand('loading');
                setNextView('*');
                return;
            }

            if (nextView) {
                clearTimeout(valueChangeTimer);
                setValueChangeTimer(null);
                setNextView('');
            }

            // Устанавливаем текущий экран
            setCurrentViewName(command, {});
            setCheckingText(false);
            setTimeout(() => setCheckingText(true), 2000);
            changeSelectedCommand(command);
            clean([value], null);
            setValue('');
            resetText();
        }
    }, [results]);

    // Если у команды установлено значение на проверку других команд
    useEffect(() => {
        if (notCommandsCheckedList.length > 0) {
            if (notCommandsCheckedList.includes(selectedCommand)) {
                setCheckCommands(false);
            } else {
                setCheckCommands(true);
            }
        }
    }, [selectedCommand, notCommandsCheckedList]);

    // При изменении команды отчистка инпута
    useEffect(() => {
        setValue('');
        resetText();
    }, [currentView]);

    return (
        <DialogContext.Provider
            value={{
                value,
                setValue,
                resetText,
                record,
                setRecord,
                translated,
                translatedText,
                setTextCleanup,
                setCheckCommands,
                compareTexts,
                results,
                setSelectedCommand: setCurrentViewName,
                currentCommand: currentView,
                instantSetValue,
                onStageChange: setTextToCompare,
                setCheckFullText,
                onTestFinish: () => changeSelectedCommand(null),
                aleshaSpeaking,
                clean,
            }}
        >
            {children}
        </DialogContext.Provider>
    );
};

export { DialogContext, DialogContextProvider };
