const associations = {
    'Алёша': {
        voice: 'filipp',
        emotion: 'neutral'
    },
    'Королев': {
        voice: 'filipp',
        emotion: 'neutral'
    },
    'Суворов': {
        voice: 'filipp',
        emotion: 'neutral'
    },
    'Можайский': {
        voice: 'ermil',
        emotion: 'neutral'
    },
    'Клава Кока': {
        voice: 'oksana',
        emotion: 'neutral'
    },
    'Ковалевская': {
        voice: 'jane',
        emotion: 'neutral'
    }
}


/**
 * Получемение параметров озвучки по автару
 * @param {String} avatarName - название аватара
 */
const getVoiceByAvatar = (avatarName = 'Алёша') => {
    return {
        lang: 'ru-RU',
        speed: 1,
        ...associations[avatarName]
    }
}

export {
    getVoiceByAvatar
}