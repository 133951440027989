import React, { useContext, useEffect } from 'react';

import { useDrag, DragPreviewImage } from 'react-dnd';

import { DnDContext } from '../dndContext';

import styles from './style.module.scss';

const Item = ({
    item = {},
    isDropped = false,
    isOverOnContainer = false,
    droppedItemsCount = 0,
}) => {
    const [{ opacity, isDragging }, drag, preview] = useDrag(
        () => ({
            type: item.type,
            item: { ...item },
            collect: (monitor) => ({
                opacity: monitor.isDragging() ? 0 : 1,
                isDragging: monitor.isDragging(),
            }),
        }),
        [item]
    );
    const { setDragsElements, playAudio, stopAudio } = useContext(DnDContext);

    useEffect(() => {
        return () => {
            stopAudio(false);
        };
    }, []);

    useEffect(() => {
        if (isDragging) {
            setDragsElements(item);
            playAudio(
                /blob:http/g.test(item.item_audio)
                    ? item.item_audio
                    : `${process.env.REACT_APP_API_URL}${item.item_audio}`,
                () => {},
                !(droppedItemsCount > 0)
            );
        } else {
            if (droppedItemsCount > 0) {
                stopAudio(false);
            }
            setDragsElements(null);
        }
    }, [isDragging]);

    return (
        !isDropped && (
            <>
                <DragPreviewImage
                    connect={preview}
                    src={`${process.env.REACT_APP_API_URL}{item.image}`}
                />
                <div
                    className={styles.root}
                    ref={drag}
                    style={{
                        opacity,
                    }}
                >
                    <img
                        src={`${process.env.REACT_APP_API_URL}${item.image}`}
                        alt={item.name}
                        style={{
                            width: isOverOnContainer ? '110%' : '100%',
                        }}
                    />
                </div>
            </>
        )
    );
};

export { Item };
