import { all } from "redux-saga/effects";
import { authWatcher } from "./auth/authSaga";
import { profileWatcher } from "./profile/profileSaga";
import { teacherWatcher } from "./teacher/teacherSaga";
import { courseWatcher } from "./courses/courseSaga";
import { testsWatcher } from './tests/testsSaga'

export function* rootSaga() {
  yield all([
    authWatcher(),
    profileWatcher(),
    teacherWatcher(),
    courseWatcher(),
    testsWatcher()
  ]);
}
