import React from 'react'

import { toClassNames } from '@helps'

import styles from './style.module.scss'

const LoadingView = () => {
    return (
        <div className={styles.container}>
            <div className={styles.dots_container}>
                <div className={toClassNames(styles.dot, styles.dot__1)} />
                <div className={toClassNames(styles.dot, styles.dot__2)} />
                <div className={toClassNames(styles.dot, styles.dot__3)} />
            </div>
        </div>
    )
}

export { LoadingView }