import React, { useState } from 'react';

import { FiTrash2 } from 'react-icons/fi';

import { Button2, AudioPlayer } from '@c';
import styles from './style.module.scss';

const Audios = ({
    value = [],
    onChange = () => {},
    audiosClassName = '',
    audioClassName = '',
    iconClassName = '',
    buttonClassName = '',
    buttonsClassName = '',
}) => {
    const [isAddAudio, setIsAddAudio] = useState(false);
    const [audio, setAudio] = useState({
        name: '',
        src: '',
    });

    return (
        <>
            <div className={styles.audios_container}>
                <AudioPlayer
                    withoutHook={true}
                    audios={value}
                    containerClassName={styles.audios}
                    onDelete={(i) =>
                        onChange(value.filter((_, idx) => idx !== i))
                    }
                />
            </div>
            {isAddAudio ? (
                <>
                    <input
                        value={audio.src}
                        onChange={({ target: { value } }) =>
                            setAudio((prev) => ({ ...prev, src: value }))
                        }
                        placeholder="Ссылка на аудиозапись"
                    />
                    <input
                        value={audio.name}
                        onChange={({ target: { value } }) =>
                            setAudio((prev) => ({ ...prev, name: value }))
                        }
                        placeholder="Название аудиозаписи"
                    />
                    <div className={buttonsClassName}>
                        <Button2
                            title="Добавить"
                            className={buttonClassName}
                            onClick={() => {
                                onChange([...value, audio]);
                                setAudio({ name: '', src: '' });
                                setIsAddAudio(false);
                            }}
                        />
                        <Button2
                            title="Отмена"
                            className={buttonClassName}
                            onClick={() => setIsAddAudio(false)}
                        />
                    </div>
                </>
            ) : (
                <Button2
                    title="Добавить аудиозапись"
                    className={buttonClassName}
                    onClick={() => setIsAddAudio(true)}
                />
            )}
        </>
    );
};

export { Audios };
