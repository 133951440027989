import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';

import { Button } from '@c/';
import { useActions } from '@h';
import {
    getVideos,
    addVideoView,
    addVideoLike,
    deleteVideoLike,
} from '@a/course';
import { courseActions } from '@r/courses/courseSlice';
import { profileActions } from '@r/profile/profileSlice';
import { DialogContext, ViewContext, viewInitState } from '../../../utils';

import { ThemeSelector } from '../components';
import { VideoSelector } from './VideoSelector';
import { Video } from './Video';

import styles from './style.module.scss';

const VideoLesson = () => {
    const {
        currentVideoLessonCourse,
        currentVideoLessonTheme,
        videoLessonThemes,
    } = useSelector(({ course }) => course);
    const {
        setCurrentVideoLessonCourse,
        setCurrentVideoLessonTheme,
        videoLessonThemesStart,
    } = useActions(courseActions);
    const { changeSelectedCommand } = useActions(profileActions);
    const { themeSelector, setThemeSelector } = useContext(ViewContext);
    const { record, setRecord, setCheckCommands } = useContext(DialogContext);

    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [likeAdding, setLikeAdding] = useState(false);

    useEffect(() => {
        // setRecord(false);
        setCheckCommands(false);
        changeSelectedCommand('Видео уроки');
        setThemeSelector({
            ...viewInitState.themeSelector,
            course: currentVideoLessonCourse,
            themes: videoLessonThemes,
            theme: currentVideoLessonTheme,
        });

        return () => {
            setRecord(true);
            setCheckCommands(true);
            changeSelectedCommand('');
            setThemeSelector({ ...viewInitState.themeSelector });
        };
    }, []);

    useEffect(() => {
        if (!!currentVideoLessonTheme) {
            setLoading(true);
            getVideos(currentVideoLessonTheme.id).then((res) => {
                setVideos(res.data);
                setLoading(false);
            });
        }
    }, [currentVideoLessonTheme]);

    const addView = (videoId, index) => {
        addVideoView(videoId)
            .then((res) => {
                if (res.status === 200) {
                    setVideos((prev) => {
                        const tmp = [...prev];
                        tmp[index] = { ...tmp[index], ...res.data };
                        return tmp;
                    });
                }
            })
            .catch((err) => {
                console.error(err);
                setLikeAdding(false);
            });
    };

    const addLike = (videoId, index) => {
        let action = null;

        if (!videos[index].like_from_user) {
            action = addVideoLike(videoId);
        } else {
            action = deleteVideoLike(videoId);
        }

        action
            .then((res) => {
                if (res.status === 200) {
                    setVideos((prev) => {
                        const tmp = [...prev];
                        tmp[index] = res.data;
                        return tmp;
                    });
                }
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setLikeAdding(false);
            });
    };

    useEffect(() => {
        setThemeSelector((prev) => ({ ...prev, themes: videoLessonThemes }));
    }, [videoLessonThemes]);

    useEffect(() => {
        // console.log(themeSelector);
        if (themeSelector.course && !themeSelector.theme) {
            setCurrentVideoLessonTheme(null);
        } else if (themeSelector.course && themeSelector.theme) {
            setRecord(false);
        }
    }, [themeSelector.theme]);

    useEffect(() => {
        // console.log(record);
    }, [record]);

    return (
        <div className={styles.wrapper}>
            {currentVideoLessonTheme ? (
                <>
                    <div className={styles.main}>
                        {selectedVideo !== null ? (
                            <Video
                                video={videos[selectedVideo]}
                                onLike={() => {
                                    setLikeAdding(true);
                                    addLike(
                                        videos[selectedVideo].id,
                                        selectedVideo
                                    );
                                }}
                                likeAdding={likeAdding}
                            />
                        ) : (
                            <VideoSelector
                                videos={videos}
                                themeName={currentVideoLessonTheme.theme}
                                onClick={(val) => setSelectedVideo(val)}
                                loading={loading}
                            />
                        )}
                    </div>
                    {selectedVideo !== null ? (
                        <Button
                            onClick={() => {
                                addView(
                                    videos[selectedVideo].id,
                                    selectedVideo
                                );
                                setSelectedVideo(null);
                            }}
                            className={styles.back_button}
                        >
                            Назад
                        </Button>
                    ) : (
                        <Button
                            onClick={() => {
                                setVideos([]);
                                setThemeSelector((prev) => ({
                                    ...prev,
                                    theme: null,
                                }));
                                setCurrentVideoLessonTheme(null);
                            }}
                            className={styles.back_button}
                        >
                            Другая тема
                        </Button>
                    )}
                </>
            ) : (
                <div className={styles.theme_selector_container}>
                    <ThemeSelector
                        selectedCourse={currentVideoLessonCourse}
                        onCourseSelect={(course) => {
                            setThemeSelector((prev) => ({ ...prev, course }));
                            setCurrentVideoLessonCourse(course);
                            if (
                                course &&
                                course.CourseID !==
                                    currentVideoLessonCourse?.CourseID
                            ) {
                                videoLessonThemesStart({
                                    id: course.CourseID,
                                    type: 'videos',
                                });
                            }
                        }}
                        themes={videoLessonThemes}
                        onThemeSelect={(theme) => {
                            setThemeSelector((prev) => ({ ...prev, theme }));
                            setCurrentVideoLessonTheme(theme);
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export { VideoLesson };
