import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { toClassNames } from '@helps';

import { FiUser, FiEye, FiHeart } from 'react-icons/fi';
import styles from './style.module.scss';

const Card = ({ info = {}, onClick = () => {} }) => {
    const [duration, setDuration] = useState('');

    useEffect(() => {
        const duration = moment.utc(
            moment.duration(info.duration, 'seconds').asMilliseconds()
        );
        setDuration(
            duration.hours() > 0
                ? duration.format('HH:mm:ss')
                : duration.format('mm:ss')
        );
    }, [info]);

    return (
        <div className={styles.card} onClick={onClick}>
            <div className={styles.preview}>
                <img src={info.preview} alt={info.title} />
                {duration && <span>{duration}</span>}
            </div>
            <div className={styles.info}>
                <span className={styles.title}>{info.title}</span>
                <div className={styles.service}>
                    <a
                        className={styles.author_avatar}
                        href={info.author_link}
                        target="_blank"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <FiUser />
                    </a>
                    <div className={styles.info}>
                        <a
                            className={styles.name}
                            href={info.author_link}
                            target="_blank"
                            onClick={(e) => e.stopPropagation()}
                        >
                            {info.author_name}
                        </a>
                        <div className={styles.counts}>
                            <div className={styles.count}>
                                <FiEye className={styles.view} />
                                <span>{info.view_count}</span>
                            </div>
                            <div className={styles.count}>
                                <FiHeart
                                    className={toClassNames(
                                        styles.like,
                                        info.like_from_user &&
                                            styles.like__selected
                                    )}
                                />
                                <span>{info.like_count}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <span href={info.service}>Источник: {info.service}</span>
                </div>
            </div>
        </div>
    );
};

export { Card };
