import React from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { Button } from '@c';

import styles from './style.module.scss';

export const Controllers = ({ setView, disable = false }) => {
    return (
        <div className={styles.wrapper}>
            <Button onClick={() => setView()} disabled={disable}>
                <FiArrowLeft className={styles.icon} size={20} />
                Меню
            </Button>
        </div>
    );
};
