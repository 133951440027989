import React, { useCallback, useState, useRef, useEffect } from 'react';
import YouTube from 'react-youtube';

import { Loader } from '@c';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { toClassNames } from '@helps/';

import { Info } from './Info';

import styles from './style.module.scss';

const YouTubeCorusel = ({
    videos = [],
    infos = [],
    onLike = () => {},
    likeAdding = false,
    className = '',
    style = {},
}) => {
    const [currentItem, setCurrentItem] = useState(0);
    const [loading, setLoading] = useState(true);

    const videoRef = useRef(null);

    const changeItem = useCallback(
        (index) => {
            if (!loading) {
                videoRef.current?.contentWindow?.postMessage(
                    JSON.stringify({
                        type: 'player:stop',
                        data: {},
                    }),
                    '*'
                );
                setCurrentItem(index);
            }
        },
        [loading, videoRef]
    );

    useEffect(() => {
        if (videoRef.current) {
            // /**@type {HTMLElement} */
            // const player = videoRef.current.container;
            // console.log(videoRef.current.container);
            // console.log(
            //     videoRef.current.container.getElementsByClassName(
            //         'ytp-chrome-top'
            //     )
            // );
        }
    }, [videoRef]);

    return (
        <div
            className={toClassNames(styles.container, className)}
            style={style}
        >
            <div className={styles.top}>
                {videos.length > 1 && (
                    <div
                        className={toClassNames(
                            styles.button_prev,
                            loading && styles.button_prev_loading
                        )}
                        onClick={() =>
                            currentItem - 1 < 0
                                ? changeItem(videos.length - 1)
                                : changeItem(currentItem - 1)
                        }
                    >
                        <FiChevronLeft />
                    </div>
                )}
                <div className={styles.video}>
                    <YouTube
                        videoId={videos[currentItem]}
                        onReady={() => setLoading(false)}
                        showCaptions={false}
                        annotations={false}
                        showRelatedVideos={false}
                        id={'player'}
                        ref={videoRef}
                    />
                    {loading && (
                        <div className={styles.preloader}>
                            <Loader color="#006eff" className={styles.loader} />
                        </div>
                    )}
                </div>
                {videos.length > 1 && (
                    <div
                        className={toClassNames(
                            styles.button_next,
                            loading && styles.button_next_loading
                        )}
                        onClick={() =>
                            currentItem + 1 > videos.length - 1
                                ? changeItem(0)
                                : changeItem(currentItem + 1)
                        }
                    >
                        <FiChevronRight />
                    </div>
                )}
            </div>
            <Info
                info={infos[currentItem]}
                onLike={() => onLike(infos[currentItem].id, currentItem)}
                likeAdding={likeAdding}
            />
            {videos.length > 1 && (
                <div className={styles.pagination}>
                    {videos.map((_, i) => (
                        <div
                            key={i}
                            className={
                                i !== currentItem
                                    ? styles.pagination_item
                                    : !loading
                                    ? styles.pagination_item_selected
                                    : styles.pagination_item
                            }
                            onClick={() => changeItem(i)}
                        >
                            {i === currentItem && <div />}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export { YouTubeCorusel };
