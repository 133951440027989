import { useCallback, useEffect } from 'react';
import { io, Socket } from 'socket.io-client';

/** @type {Socket|null} */
let socket = null;

const useSocket = () => {
    const initSocket = useCallback(() => {
        if (socket === null) {
            const _socket = io(process.env.REACT_APP_API_SOCKET_URL, {
                transports: ['websocket', 'pooling', 'flashsocket'],
            });

            _socket.on('connect', () => console.info('Socket connected'));
            _socket.on('disconnect', () => console.info('Socket disconnected'));

            // setSocket(_socket)
            socket = _socket;
        }
    }, []);

    const disconnectSocket = useCallback(() => {
        if (socket !== null) {
            socket.disconnect();
            socket = null;
        }
    }, [socket]);

    return { socket, initSocket, disconnectSocket };
};

export { useSocket };
