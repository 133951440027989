import React, { createContext, useEffect, useState } from 'react';

import { useActions } from '@h';
import { profileActions } from '@r/profile/profileSlice';

const aleshaHeadContextInitState = {
    aleshaSpeaking: false,
    headData: {
        name: 'Алёша',
        idle: 'head_idle_vp9.webm',
        talking: 'head_talking_vp9.webm',
        voice: 'filipp',
    },
};

const AleshaHeadContext = createContext({
    aleshaSpeaking: aleshaHeadContextInitState.aleshaSpeaking,
    setAleshaSpeaking: () => {},
    headData: { ...aleshaHeadContextInitState.headData },
    setHeadData: () => {},
});

const AleshaHeadContextProvider = ({ children }) => {
    // говорит/не говорит
    const [aleshaSpeaking, setAleshaSpeaking] = useState(
        aleshaHeadContextInitState.aleshaSpeaking
    );
    // Информация по выбранному аватару
    const [headData, setHeadData] = useState(
        aleshaHeadContextInitState.headData
    );
    const [loaded, setLoaded] = useState(false);
    const { changeVoiceOptions } = useActions(profileActions);

    useEffect(() => {
        // При изменении устанавливаем новые значения
        if (loaded) {
            localStorage.setItem('avatarData', JSON.stringify(headData));
        }
    }, [headData]);

    useEffect(() => {
        // При создании компонента достаем данные пользователя
        const tmp = JSON.parse(localStorage.getItem('avatarData'));
        // если они етсь
        if (tmp) {
            // естанавливаем
            setHeadData(tmp);
            changeVoiceOptions(tmp.name);
        }
        // данные установлены
        setLoaded(true);
    }, []);

    return (
        <AleshaHeadContext.Provider
            value={{
                aleshaSpeaking,
                setAleshaSpeaking,
                headData,
                setHeadData,
            }}
        >
            {children}
        </AleshaHeadContext.Provider>
    );
};

export {
    AleshaHeadContext,
    AleshaHeadContextProvider,
    aleshaHeadContextInitState,
};
