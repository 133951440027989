import React, {
    useEffect,
    useMemo,
    useState,
    useContext,
    useRef,
    useCallback,
} from 'react';
import { useSelector } from 'react-redux';

import { useSocket, useCompare, useActions, useAudio } from '@h';
import { useLoadAudioFile } from '@h/useLoadAudioFile';
import { authActions } from '@r/auth/authSlice';
import { Question } from './Question';
import { AleshaHeadContext, ViewContext, DialogContext } from '../../../utils';
import { TTS } from '@helps/';

import styles from './style.module.scss';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

function StudentTypeTestView({
    test: initTest = [],
    userInfo = {},
    onTestFinishProp = () => {},
}) {
    const { setAleshaSpeaking } = useContext(AleshaHeadContext);
    const {
        setView,
        lessonPayload,
        setLessonPayload,
        setControl,
        disableMenuButton,
    } = useContext(ViewContext);
    const {
        value,
        setValue,
        onStageChange,
        onTestFinish,
        resetText,
        setTextCleanup,
        setCheckFullText,
        setCheckCommands,
        record,
        setRecord,
        clean,
    } = useContext(DialogContext);

    const { socket } = useSocket();
    const { results, resultsWithWords, resetResults } = useCompare(socket);
    const { typeInformationPerceptChange } = useActions(authActions);

    const [test, setTest] = useState([...initTest]);
    const [started, setStarted] = useState(false);
    const [currentIdx, setCurrentIdx] = useState(0);
    const [isAudioFinish, setIsAudioFinish] = useState(false);
    const [answers, setAnswers] = useState(Array(test.length).fill(null));
    const [result, setResult] = useState('');
    const [finished, setFinished] = useState(false);
    const [transitionToNext, setTransitionToNext] = useState(true);

    const expectedResponsesForTestStartYes = useMemo(() => [
        'Давай',
        'Да',
        'Конечно',
        'С удовольствием',
        'Хорошо',
        'Окей',
        'Ок',
        'Отлично',
    ]);
    const expectedResponsesForTestStartNo = useMemo(() => [
        'Нет спасибо',
        'В другой раз',
        'Не хочу',
        'Нет',
        'Можно не надо',
    ]);
    const expectedResponsesForTestStart = useMemo(
        () => [
            ...expectedResponsesForTestStartYes,
            ...(!disableMenuButton ? expectedResponsesForTestStartNo : []),
        ],
        []
    );
    const expectedResponsesForTestFinish = useMemo(
        () => [
            'Хорошо',
            'Классно',
            'Круто',
            'Понял',
            'Замечательно',
            'Окей',
            'Ок',
            'Отлично',
            'Хорошо',
        ],
        []
    );

    const [remindAudio, setRemindAudio] = useState(null);


    /**@type {HTMLAudioElement} */
    const audio = useMemo(() => new Audio(), []);
    const dataPromiseCancelRef = useRef(null);
    const { getFileUrl } = useLoadAudioFile(dataPromiseCancelRef);

    /**
     * @callback getAndPlayAudioCallback
     * @param {String} url
     * @returns {void}
     *
     * @param {String} text
     * @param {getAndPlayAudioCallback} onEnd
     */
    const getAndPlayAudio = (
        text,
        onEnd = () => {},
        startRecordOnEnd = true
    ) => {
        setIsAudioFinish(false);
        getFileUrl(
            text,
            () => {},
            (url) => playAudioFile(url, onEnd, startRecordOnEnd)
        );
    };

    const playAudioFile = (url, onEnd = () => {}, startRecordOnEnd = true) => {
        audio.src = url;
        audio.onended = () => {
            setIsAudioFinish(true);
            // Отключаем анимацию
            setAleshaSpeaking(false);
            // Включаем микрофон
            startRecordOnEnd && setRecord(true);
            onEnd();
        };
        audio.play();
        setAleshaSpeaking(true);
        record && setRecord(false);
        setValue('');
        resetText();
    };

    const _stopAudio = (withRecordStart = true) => {
        if (
            !dataPromiseCancelRef.current.finished &&
            !dataPromiseCancelRef.current.cancelled
        ) {
            console.log('canceling');
            dataPromiseCancelRef.current.cancel();
        }
        console.log(0);
        setValue('');
        resetText();
        setAleshaSpeaking(false);
        // withRecordStart && !record && setRecord(true);

        withRecordStart && setRecord((prev) => prev || true);
        audio.pause();
    };

    const reminder = async () => {
        if (!remindAudio) {
            getAndPlayAudio(
                `${userInfo.firstname}, перефразируй ответ и нажми кнопку далее`
            );
        } else {
            // playAudio(remindAudio);
        }
    };

    const finishTest = async () => {
        const tmp = Array(3).fill(0);

        answers.forEach((el) => el !== null && tmp[el]++);

        let res;
        const idx = tmp.indexOf(Math.max(...tmp));

        switch (idx) {
            case 0:
                res = 'Аудиал';
                break;
            case 1:
                res = 'Визуал';
                break;
            case 2:
                res = 'Кинестетик';
                break;
            default:
                break;
        }

        tmp.sort((a, b) => b - a);
        if (tmp[0] === tmp[1]) res = 'Дискрет';

        typeInformationPerceptChange(res);
        getAndPlayAudio(
            `${userInfo.firstname}, по результатам тэста ты - ${res}! Молодец!`
        );

        setLessonPayload((prev) => ({
            ...prev,
            typeInformationPerceptChange: res,
        }));

        setResult(res);
    };

    const startText = async () => {
        getAndPlayAudio(
            `${userInfo.firstname}, у меня не получилось узнать твой тип восприятия, давай пройдем небольшой тест? Без этого мы с тобой не сможем работать дальше`
        );
        setLessonPayload({});
    };

    useEffect(() => {
        startText();

        return () => {
            setLessonPayload({});
            _stopAudio();
            setCheckCommands(true);
            setCheckFullText(true);
        };
    }, []);

    const [dndAudiosLoaded, setDnDAudioLoaded] = useState(false);
    useEffect(() => {
        if (!dndAudiosLoaded) {
            const destIndex = test.findIndex(
                (el) => el.type === 'distribution'
            );
            const texts = test[destIndex].payload.items.map(
                (el) => el.description
            );
            const textsUrls = new Array(texts.length);

            const textsPromises = texts.map(
                (el, i) =>
                    new Promise((res, rej) =>
                        TTS(el)
                            .then((res) => URL.createObjectURL(res.data))
                            .then((url) => ((textsUrls[i] = url), res(url)))
                            .catch((err) => (console.error(err), rej(err)))
                    )
            );

            const updateTest = (textsUrls = new Array(texts).fill('')) => {
                setTest((prev) => {
                    const tmp = JSON.parse(JSON.stringify(prev));
                    tmp[destIndex].payload.items = tmp[
                        destIndex
                    ].payload.items.map((el, i) => ({
                        ...el,
                        item_audio: textsUrls[i],
                    }));

                    return tmp;
                });
            };

            Promise.all(textsPromises)
                .then((_) => {
                    setDnDAudioLoaded(true);
                    updateTest(textsUrls);
                })
                .catch((err) => {
                    console.error(err);
                    updateTest(
                        test[destIndex].payload.items.map(
                            (el) =>
                                `${process.env.REACT_APP_API_URL}/${el.item_audio}`
                        )
                    );
                });
        }
    }, [test]);

    useEffect(() => {
        if (currentIdx < test.length && started) {
            getAndPlayAudio(
                test[currentIdx].type === 'distribution'
                    ? `${test[currentIdx].question} Распредели предметы по соответствующим корзинкам.`
                    : test[currentIdx].question,
                () => {},
                test[currentIdx].type !== 'distribution'
            );
        }
        if (currentIdx >= test.length) {
            setValue('');
            resetResults();
            finishTest();
        }
    }, [currentIdx, started]);

    useEffect(() => {
        if (started) {
            if (!finished && test[currentIdx]) {
                if (test[currentIdx].type === 'readingSpeed') {
                    onStageChange([test[currentIdx].payload.text]);
                } else {
                    onStageChange(test[currentIdx].answers);
                }
            }

            if (finished) {
                onStageChange(expectedResponsesForTestFinish);
            }
        } else {
            onStageChange(expectedResponsesForTestStart);
        }
    }, [started, finished, currentIdx]);

    const [checkResults, setCheckResults] = useState(true);
    useEffect(() => {
        if (!checkResults) return;

        if (started) {
            if (finished && results.filter((el) => el >= 0.5).length > 0) {
                setCheckResults(false);
                clean([value, ...expectedResponsesForTestFinish]);
                setValue('');
                resetText();
                setLessonPayload((prev) => ({ ...prev, finished: true }));
                onTestFinish();
                onTestFinishProp();
                setTextCleanup(true);
                return;
            }

            if (currentIdx < test.length) {
                if (
                    results[0] > 0.8 &&
                    lessonPayload.stoped &&
                    test[currentIdx].type &&
                    test[currentIdx].type === 'readingSpeed'
                ) {
                    setAnswers((prev) => {
                        if (
                            lessonPayload.speed >=
                                test[currentIdx].answers[0] &&
                            lessonPayload.speed <= test[currentIdx].answers[1]
                        )
                            prev[currentIdx] = 2;
                        else
                            prev[currentIdx] = test[currentIdx].answers[0]
                                ? 1
                                : 0;
                        return [...prev];
                    });
                }
                // else if (results.filter((el) => el >= 0.1).length > 0) {
                //     if (
                //         Math.max(...results) / 2 >
                //         results.reduce((acc, cur) => acc + cur) -
                //             Math.max(...results)
                //     ) {
                //         // setValue('')
                //         // setAnswers(prev => {
                //         //     prev[currentIdx] = results.indexOf(Math.max(...results))
                //         //     return [...prev]
                //         // })
                //         // setCurrentIdx(prev => prev + 1)
                //     }
                // }
            }
        } else {
            setTextCleanup(false);
            if (
                results
                    .slice(0, expectedResponsesForTestStartYes.length)
                    .filter((el) => el >= 0.5).length > 0
            ) {
                // setCheckResults(false);
                clean([value, ...expectedResponsesForTestStartYes], null);
                setValue('');
                resetText();
                setStarted(true);
            }
            if (
                results
                    .slice(expectedResponsesForTestStartYes.length)
                    .filter((el) => el >= 0.5).length > 0
            ) {
                setCheckResults(false);
                clean([value, ...expectedResponsesForTestStartYes], null);
                setLessonPayload((prev) => ({ ...prev, finished: true }));
                setAleshaSpeaking(false);
                onTestFinish();
                onTestFinishProp();
                setValue('');
                resetText();
                setTextCleanup(true);
                setView(null);
            }
        }
    }, [results, lessonPayload]);

    const toPrev = useCallback(() => {
        clean([value], null);
        setValue('');
        resetText();
        setCurrentIdx((prev) => prev - 1);
    }, [currentIdx]);

    const toNext = useCallback(() => {
        if (results.filter((el) => el > 0).length > 0 && transitionToNext) {
            setAnswers((prev) => {
                prev[currentIdx] = results.indexOf(Math.max(...results));
                return [...prev];
            });
            clean([value], null);
            setValue('');
            resetText();
            setCurrentIdx(
                (prev) => (prev === 10 && setFinished(true), prev + 1)
            );
        }
    }, [
        results,
        transitionToNext,
        currentIdx,
        setAnswers,
        setFinished,
        setCurrentIdx,
    ]);

    useEffect(() => {
        setControl((prev) => ({
            ...prev,
            transitionToNext:
                results.filter((el) => el > 0).length > 0 && transitionToNext,
            toNext,
            toPrev,
            currentIdx,
        }));
    }, [transitionToNext, results]);

    if (test.length > 0 && answers.length > 0)
        return !started ? (
            <>
                <div className={styles.availableCommands}>
                    {userInfo.firstname}, у меня не получилось узнать твой тип
                    восприятия, давай пройдем небольшой тест? <br /> Без этого
                    мы с тобой не сможем работать дальше
                </div>
                <div className={styles.expected_responses}>
                    <p className={styles.header}>Ожидаемые ответы:</p>
                    <div className={styles.list}>
                        {expectedResponsesForTestStart.map((el, i) => (
                            <div
                                key={`${i}`}
                                className={styles.list_item}
                                onClick={
                                    i < expectedResponsesForTestStartYes.length
                                        ? () => setValue(el)
                                        : () => {
                                              setAleshaSpeaking(false);
                                              setView(null);
                                              onTestFinishProp();
                                              onTestFinish();
                                          }
                                }
                            >
                                <p>{el}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </>
        ) : result !== '' ? (
            <>
                <div className={styles.availableCommands}>
                    {userInfo.firstname}, по результатам теста ты - {result}!
                    Молодец
                </div>
                <div className={styles.expected_responses}>
                    <p className={styles.header}>Ожидаемые ответы:</p>
                    <div className={styles.list}>
                        {expectedResponsesForTestFinish.map((el, i) => (
                            <div
                                key={`${i}`}
                                className={styles.list_item}
                                onClick={() => {
                                    setLessonPayload((prev) => ({
                                        ...prev,
                                        finished: true,
                                    }));
                                    setValue('');
                                    onTestFinish();
                                    onTestFinishProp();
                                }}
                            >
                                <p>{el}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </>
        ) : (
            currentIdx < test.length && (
                <>
                    <Question
                        index={currentIdx <= 11 && currentIdx + 1}
                        question={test[currentIdx]}
                        showAnswers={true}
                        results={results}
                        selectAnswer={() => {}}
                        value={value}
                        setValue={setValue}
                        onValueChange={(val) => setTransitionToNext(val)}
                        resultsWithWords={resultsWithWords}
                        isAudioFinish={isAudioFinish}
                        resetText={resetText}
                        stopAudio={_stopAudio}
                    />
                    {currentIdx > 0 && (
                        <div className={styles.previous_question}>
                            <FiChevronLeft onClick={() => toPrev()} size={35} />
                            <p>Назад</p>
                        </div>
                    )}
                    {currentIdx < test.length && (
                        <div
                            className={`${styles.next_question} ${
                                (!transitionToNext ||
                                    results.filter((el) => el > 0).length ===
                                        0) &&
                                styles.arrow_opacity
                            }`}
                        >
                            <FiChevronRight
                                onClick={() => toNext()}
                                size={35}
                            />
                            <p>Далее</p>
                        </div>
                    )}
                </>
            )
        );
    else return <></>;
}

export { StudentTypeTestView };
