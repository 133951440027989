import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { useActions } from '@h';
import { courseActions } from '@r/courses/courseSlice';
import { toClassNames } from '@helps/';

import { Course } from './TestView';
import { TestContextProvider } from './utils';

import { FiSidebar, FiLock } from 'react-icons/fi';

import styles from './style.module.scss';

const Test = () => {
    const { schemeId } = useSelector(({ teacher }) => teacher);
    const { currentNode } = useSelector(({ course }) => course);
    const { currentNodeStart, setCurrentTheme } = useActions(courseActions);

    const [testActive, setTestActive] = useState(false);
    const [fixed, setFixed] = useState(false);
    const [type, setType] = useState(null);

    useEffect(() => {
        if (schemeId && type !== null) {
            currentNodeStart({ courseId: schemeId, type });
        }
    }, [schemeId, currentNodeStart, type]);

    useEffect(() => {
        setCurrentTheme(schemeId);
    }, [schemeId]);

    return (
        <div
            className={toClassNames(
                styles.mainBlock,
                (testActive || fixed) && styles.mainBlock__active
            )}
        >
            <div
                className={styles.marker}
                onMouseLeave={() => setTestActive(false)}
                onMouseEnter={() => setTestActive(true)}
            >
                <div className={styles.button} onClick={() => setFixed(!fixed)}>
                    {fixed ? <FiLock size={17} /> : <FiSidebar size={17} />}
                </div>
            </div>
            <div
                className={styles.test_container}
                onMouseLeave={() => setTestActive(false)}
                onMouseEnter={() => setTestActive(true)}
            >
                <TestContextProvider>
                    <div className={styles.header}>
                        {schemeId ? (
                            <span>Тестовое воспроизведение</span>
                        ) : (
                            <span className={styles.warning}>
                                Необходимо выбрать курс
                            </span>
                        )}
                        <div className={styles.type_select_container}>
                            <span>Для типа восприятия: </span>
                            <FormControl variant="standard">
                                <Select
                                    className={styles.type_select}
                                    disableUnderline={true}
                                    labelId="typeSelectLabel"
                                    value={type || 'null'}
                                    onChange={({ target: { value } }) => {
                                        setType(value);
                                    }}
                                >
                                    <MenuItem value="null">
                                        <em>Не выбрано</em>
                                    </MenuItem>
                                    <MenuItem value="Визуал">
                                        <span>Визуал</span>
                                    </MenuItem>
                                    <MenuItem value="Аудиал">
                                        <span>Аудиал</span>
                                    </MenuItem>
                                    <MenuItem value="Кинестетик">
                                        <span>Кинестетик</span>
                                    </MenuItem>
                                    <MenuItem value="Дискрет">
                                        <span>Дискрет</span>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    {currentNode && <Course type={type} />}
                </TestContextProvider>
            </div>
        </div>
    );
};

export { Test };
