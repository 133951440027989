import axios from 'axios'
import { BASE_URL } from '../constants/api'

export const configureApi = store => {
  const instance = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
  })

  // instance.interceptors.response.use(
  //   response => {
  //     if (response.data.error && response.data.error.code === 401) {
  //       store.dispatch(resetAuthData())
  //     }
  //     return response
  //   },
  //   error => {
  //     console.error(error)
  //     if (error.response.status === 401) {
  //       store.dispatch(resetAuthData())
  //     }
  //     return Promise.reject(error)
  //   }
  // )

  return instance
}
