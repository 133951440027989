import { toClassNames } from '@helps/classNames';
import React from 'react';

import styles from './style.module.scss';

const Accurate = ({ className = '', value = '', onChange = () => {} }) => {
    return (
        <input
            className={toClassNames(styles.input, className)}
            value={value}
            onChange={({ target: { value } }) => onChange(value)}
        />
    );
};

export { Accurate };
