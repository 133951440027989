import React, { useState, useEffect } from 'react';

import {
    questionTypes,
    QuestionAnswer,
    QuestionTypesInfo,
} from '../QuestionAnswer';
import { Quill } from '@c';
import { Input, AudioPlayer } from '../../components';
import { InfoBlock } from '../../InfoBlock';

import styles from './style.module.scss';

const Form = ({ data = {}, onChange = () => {}, uniq = '' }) => {
    const [_data, setData] = useState({ ...data });

    useEffect(() => {
        onChange(_data);
    }, [_data]);

    return (
        <div className={styles.main}>
            <div className={styles.input_block}>
                <p>Вопрос</p>
                {typeof _data.text === 'string' && (
                    <Quill
                        uniq={uniq}
                        value={_data.text}
                        onChange={(value) =>
                            setData((prev) => ({ ...prev, text: value }))
                        }
                    />
                )}
                {data.speech && <AudioPlayer url={data.speech} />}
                <InfoBlock />
            </div>
            <div className={styles.input_block}>
                <p>Изображения</p>
                <Input
                    type="images"
                    value={_data.images}
                    onChange={(images) =>
                        setData((prev) => ({ ...prev, images }))
                    }
                    imagesClassName={styles.images}
                    imageClassName={styles.image}
                    iconClassName={styles.image_icon}
                    buttonsClassName={styles.buttons}
                    buttonClassName={styles.button}
                />
            </div>
            <div className={styles.input_block}>
                <p>Видео</p>
                <Input
                    type="videos"
                    value={_data.videos}
                    onChange={(videos) =>
                        setData((prev) => ({ ...prev, videos }))
                    }
                    videosClassName={styles.images}
                    videoClassName={styles.image}
                    iconClassName={styles.image_icon}
                    frameClassName={styles.youtube}
                    buttonsClassName={styles.buttons}
                    buttonClassName={styles.button}
                />
            </div>
            <div className={styles.input_block}>
                <p>Решение</p>
                <Input
                    type="solution"
                    value={_data.solution}
                    buttonClassName={styles.button}
                    uniq={uniq}
                    onChange={(value) =>
                        setData((prev) => ({ ...prev, solution: value }))
                    }
                />
            </div>
            <div className={styles.input_block}>
                <p>Ответ:</p>
                <QuestionTypesInfo className={styles.question_info} />
                <div className={styles.type_selection}>
                    <div className={styles.select}>
                        <label htmlFor="type">Вид ответа:</label>
                        <select
                            id="type"
                            value={_data.type}
                            onChange={({ target: { value } }) =>
                                setData((prev) => ({ ...prev, type: value }))
                            }
                        >
                            {questionTypes.map((el, i) => (
                                <option key={i} value={el.name}>
                                    {el.title}
                                </option>
                            ))}
                        </select>
                    </div>
                    <p>Предполагаемый ответ:</p>
                    {_data.type && (
                        <QuestionAnswer
                            type={_data.type}
                            value={_data.correctAnswer}
                            onChange={(val) =>
                                setData((prev) => ({
                                    ...prev,
                                    correctAnswer: val,
                                }))
                            }
                            data={_data}
                            setData={setData}
                            uniq={uniq}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export { Form };
