import React, { useState, useEffect } from 'react';

import { AudioPlayer, Corusel, YouTubeCorusel, Button } from '@c/';
import { addVideoLike, deleteVideoLike, addVideoView } from '@a/course';

import styles from './style.module.scss';

const Question = ({ data = {}, onAnswerChange = () => {} }) => {
    const [checked, setChecked] = useState([]);
    const [_data, setData] = useState({ ...data });
    const [likeAdding, setLikeAdding] = useState(false);
    const [showSolution, setShowSolution] = useState(false);

    const addLike = (videoId, index) => {
        let action = null;

        if (!_data.videosInfo[index].like_from_user) {
            action = addVideoLike(videoId);
        } else {
            action = deleteVideoLike(videoId);
        }

        action
            .then((res) => {
                if (res.status === 200) {
                    setData((prev) => {
                        const tmp = [...prev.videosInfo];
                        tmp[index] = res.data;
                        return { ...prev, videosInfo: tmp };
                    });
                }
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setLikeAdding(false);
            });
    };

    useEffect(() => {
        onAnswerChange(checked);
    }, [checked]);

    return (
        <div className={styles.data_container}>
            <div dangerouslySetInnerHTML={{ __html: _data.text }}></div>
            <div className={styles.payload}>
                {_data?.images?.length > 0 && (
                    <div className={styles.image_container}>
                        <Corusel
                            items={_data.images.map((el, i) => (
                                <img src={el} key={i} alt={`corusel ${i}`} />
                            ))}
                        />
                    </div>
                )}
                {_data?.videos?.length > 0 && (
                    <div className={styles.video_container}>
                        <YouTubeCorusel
                            videos={_data.videos}
                            infos={_data.videosInfo}
                            onLike={(videoId, index) => {
                                setLikeAdding(true);
                                addLike(videoId, index);
                            }}
                            likeAdding={likeAdding}
                        />
                    </div>
                )}
                {_data?.audios?.length > 0 && (
                    <div className={styles.audio_container}>
                        {_data.audios?.map((el, i) => (
                            <AudioPlayer key={i} url={el.src} name={el.name} />
                        ))}
                    </div>
                )}
                {_data?.solution?.length > 0 && (
                    <div className={styles.solution_container}>
                        {showSolution ? (
                            <div className={styles.content}>
                                <div className={styles.header}>
                                    <Button
                                        className={styles.button}
                                        onClick={() => setShowSolution(false)}
                                    >
                                        <span>Скрыть решение</span>
                                    </Button>
                                </div>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: _data.solution,
                                    }}
                                ></div>
                            </div>
                        ) : (
                            <Button
                                className={styles.button}
                                onClick={() => setShowSolution(true)}
                            >
                                <span>Показать решение</span>
                            </Button>
                        )}
                    </div>
                )}
                {_data.type === 'multi' && (
                    <div className={styles.answers}>
                        {_data.answers.map((el, i) => (
                            <div className={styles.answer} key={i}>
                                <span className={styles.index}>{i + 1}</span>
                                <input
                                    className={styles.answer_is_correct}
                                    type="checkbox"
                                    onChange={({ target: { checked } }) =>
                                        checked
                                            ? setChecked((prev) => {
                                                  if (data.oneAnswer)
                                                      return [el.index];
                                                  else
                                                      return [
                                                          ...prev,
                                                          el.index,
                                                      ];
                                              })
                                            : setChecked((prev) => {
                                                  let tmp = prev.filter(
                                                      (index) =>
                                                          el.index !== index
                                                  );
                                                  return tmp;
                                              })
                                    }
                                    checked={
                                        checked.find(
                                            (index) => el.index === index
                                        ) !== undefined
                                    }
                                />
                                {!/(\<\w*)((\s\/\>)|(.*\<\/\w*\>))/gm.test(
                                    el.title
                                ) ? (
                                    <span>{el.title}</span>
                                ) : (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: el.title,
                                        }}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export { Question };
