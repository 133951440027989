import React from 'react';

import { toClassNames } from '@helps/';

import styles from './style.module.scss';

const Concurrence = ({ value = '', className = '', onChange = () => {} }) => {
    return (
        <textarea
            className={toClassNames(styles.textarea, className)}
            value={value}
            onChange={({ target: { value } }) => onChange(value)}
        />
    );
};

export { Concurrence };
