import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';

import { Button } from '@c';
import { useActions } from '@h';
import { courseActions } from '@r/courses/courseSlice';
import { profileActions } from '@r/profile/profileSlice';
import { DialogContext, ViewContext, viewInitState } from '../../../utils';

import { ThemeSelector, FileSelector } from '../components';

import styles from './style.module.scss';

const Theory = () => {
    const {
        currentTheoryCourse,
        currentTheoryTheme,
        theoryThemes,
        theoryMaterials,
    } = useSelector(({ course }) => course);
    const {
        setCurrentTheoryCourse,
        setCurrentTheoryTheme,
        theoryThemesStart,
        theoryMaterialsStart,
    } = useActions(courseActions);
    const { setRecord, setCheckCommands } = useContext(DialogContext);
    const { themeSelector, setThemeSelector } = useContext(ViewContext);
    const { changeSelectedCommand } = useActions(profileActions);

    const [_theoryMaterials, setTheoryMaterials] = useState([]);

    useEffect(() => {
        setCheckCommands(false);
        changeSelectedCommand('Теоретические материалы');
        setThemeSelector({
            ...viewInitState.themeSelector,
            course: currentTheoryCourse,
            themes: theoryThemes,
            theme: currentTheoryTheme,
        });

        return () => {
            setRecord(true);
            setCheckCommands(true);
            changeSelectedCommand('');
            setThemeSelector({ ...viewInitState.themeSelector });
        };
    }, []);

    useEffect(() => {
        if (currentTheoryTheme) {
            const { CourseID: courseId } = currentTheoryCourse;
            setTheoryMaterials([]);
            theoryMaterialsStart({
                courseId,
            });
        }
    }, [currentTheoryTheme]);

    useEffect(() => {
        if (currentTheoryCourse) {
            setTheoryMaterials(
                theoryMaterials.map((el) => ({
                    ...el,
                    downloadURI: `${process.env.REACT_APP_API_URL}/lms/?action=coursefile&cid=${currentTheoryCourse.CourseID}&file=${el.name}`,
                }))
            );
        }
    }, [theoryMaterials]);

    useEffect(() => {
        setThemeSelector((prev) => ({ ...prev, themes: theoryThemes }));
    }, [theoryThemes]);

    useEffect(() => {
        if (themeSelector.course && !themeSelector.theme) {
            setCurrentTheoryTheme(null);
        } else if (themeSelector.course && themeSelector.theme) {
            setRecord(false);
        }
    }, [themeSelector.theme]);

    return (
        <div className={styles.wrapper}>
            {currentTheoryTheme ? (
                <div className={styles.main}>
                    <FileSelector
                        files={_theoryMaterials}
                        themeName={currentTheoryTheme.theme}
                    />
                    <Button
                        className={styles.button}
                        onClick={() => {
                            setThemeSelector((prev) => ({
                                ...prev,
                                theme: null,
                            }));
                            setCurrentTheoryTheme(null);
                        }}
                    >
                        Другая тема
                    </Button>
                </div>
            ) : (
                <ThemeSelector
                    selectedCourse={currentTheoryCourse}
                    onCourseSelect={(course) => {
                        setThemeSelector((prev) => ({ ...prev, course }));
                        setCurrentTheoryCourse(course);
                        if (
                            course &&
                            course.CourseID !== currentTheoryCourse?.CourseID
                        ) {
                            theoryThemesStart({
                                id: course.CourseID,
                                type: 'theory',
                            });
                        }
                    }}
                    themes={theoryThemes}
                    onThemeSelect={(theme) => {
                        setThemeSelector((prev) => ({ ...prev, theme }));
                        setCurrentTheoryTheme(theme);
                    }}
                />
            )}
        </div>
    );
};

export { Theory };
